<template>
  <div>
    <form autocomplete="off" @submit.prevent="submitSignature">
      <div class="columns">
        <div class="column is-half is-offset-one-quarter">
          <div class="field">
            <label class="label required">E-mail</label>
            <div class="control">
              <input
                class="input"
                v-focus
                type="email"
                v-model.trim="$v.email.$model"
                :class="{ 'is-danger': $v.email.$error }"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="columns" x-show="otpSent">
        <div class="column is-4 is-offset-4">
          <div class="buttons">
            <button
              class="button is-primary is-medium is-fullwidth"
              :disabled="isLoading || $v.$anyError"
            >
              Firmar
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapMutations } from "vuex"
import { required } from "vuelidate/lib/validators"
import validatePermissions from "../../helpers/validatePermissions"
import {
  updatePersonalInformation,
  loanSignatureController
} from "@/services/user"
export default {
  data() {
    return {
      email: "",
      isLoading: false
    }
  },
  beforeMount(){
    validatePermissions("FIR","No tiene permisos para gestionar a un cliente en la pantalla de Firma");
  },
  validations: {
    email: {
      required
    }
  },
  mounted() {
    this.getCurrentEmail()
  },
  methods: {
    ...mapMutations({
      setError: "form/setError"
    }),
    getCurrentEmail() {
      this.email = this.$store.getters["user/getEmail"].toLowerCase()
    },
    async submitSignature() {
      let updatedEmail = true

      if (
        this.email.toLowerCase() !==
        this.$store.getters["user/getEmail"].toLowerCase()
      ) {
        try {
          await updatePersonalInformation(
            this.$store.getters["user/getDocumentNumber"],
            { email: this.email }
          )
        } catch (error) {
          let errorMessage =
            error.code === "PI005"
              ? "El correo electrónico ya está en uso"
              : "Error al intentar actualizar el correo. No se puede firmar el préstamo."
          updatedEmail = false
          this.$notyf.error(errorMessage)
        }
      }

      if (updatedEmail) {
        try {
          await loanSignatureController(
            this.$store.getters["user/getApprovedOrApprovedAwaitingSignature"]
              .id
          )

          this.setError({
            errorStatus: true,
            errorMessage: "Se envió el link de firma a su correo electrónico"
          })
        } catch (error) {
          this.$notyf.error("Error al intentar firmar el préstamo. ")
        }
      }
    }
  }
}
</script>

<style></style>
