<template>
  <div>
    <div v-if="getUbica">
      <div class="card mb-4">
        <header class="card-header">
          <p class="card-header-title">
            FECHA Y LUGAR DE EXPEDICÓN DE LA CEDULA
          </p>
        </header>
        <div class="card-content">
          <div class="content">
            <div v-if="getUbica.userInformation.expeditionDate.length === 0">
              No hay información
            </div>
            <div v-else>
              <li>{{ getUbica.userInformation.expeditionDate }}</li>
              <li>{{ getUbica.userInformation.expeditionPlace }}</li>
            </div>
          </div>
        </div>
      </div>
      <div class="card mb-4">
        <header class="card-header">
          <p class="card-header-title">
            CELULARES
          </p>
        </header>
        <div class="card-content">
          <div class="content">
            <div v-if="getUbica.phones.length === 0">
              No hay información
            </div>
            <div v-else>
              <li v-for="row in getUbica.phones" :key="row.id">
                {{ row.phone }}
              </li>
            </div>
          </div>
        </div>
      </div>
      <div class="card mb-4">
        <header class="card-header">
          <p class="card-header-title">
            EMAIL
          </p>
        </header>
        <div class="card-content">
          <div class="content">
            <div v-if="getUbica.emails.length === 0">
              No hay información
            </div>
            <div v-else>
              <li v-for="row in getUbica.emails" :key="row.id">
                {{ row.email }}
              </li>
            </div>
          </div>
        </div>
      </div>
      <div class="card mb-4">
        <header class="card-header">
          <p class="card-header-title">
            DIRECCIONES
          </p>
        </header>
        <div class="card-content">
          <div class="content">
            <div v-if="getUbica.addresses.length === 0">
              No hay información
            </div>
            <div v-else>
              <li v-for="row in getUbica.addresses" :key="row.id">
                {{ row.address }}
              </li>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <!-- creditos -->
      <div v-if="getLoans" class="card mb-4">
        <header class="card-header">
          <p class="card-header-title">
            CREDITOS
          </p>
        </header>
        <div class="card-content">
          <div class="content">
            <div class="columns is-multiline">
              <div class="column is-6" v-for="(v, k) in getLoans" :key="k">
                <div>{{ v.status.id }}</div>
                <div>{{ v.status.value }}</div>
                <div>{{ format(v.amount) }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="getPersonalInformation" class="card mb-4">
        <header class="card-header">
          <p class="card-header-title">
            INFORMACIÓN PERSONAL
          </p>
        </header>
        <div class="card-content">
          <div class="content">
            <div class="columns is-multiline">
              <div
                class="column is-6"
                v-for="(v, k) in getPersonalInformation"
                :key="k"
              >
                <div>
                  <strong>{{ k.toUpperCase() }}</strong
                  ><br />{{ v }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="getLaboralInformation" class="card mb-4">
        <header class="card-header">
          <p class="card-header-title">
            INFORMACIÓN LABORAL
          </p>
        </header>
        <div class="card-content">
          <div class="content">
            <div class="columns is-multiline">
              <div
                class="column is-6"
                v-for="(v, k) in getLaboralInformation"
                :key="k"
              >
                <div>
                  <strong>{{ k.toUpperCase() }}</strong>
                </div>
                <div>
                  {{ v }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex"
import moneyFormat from "@/helpers/moneyFormat"
export default {
  computed: {
    ...mapGetters({
      getUbica: "user/getUbica",
      getPersonalInformation: "user/getPersonalInformation",
      getLaboralInformation: "user/getLaboralInformation",
      getLoans: "user/getLoans"
    })
  },
  methods: {
    format(value) {
      return moneyFormat(value)
    }
  }
}
</script>
