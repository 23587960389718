<template>
  <div class="modal" :class="{ 'is-active': open }">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">{{ title }}</p>
        <button
          class="delete"
          aria-label="close"
          @click="closeModal"
          title="Cerrar"
        ></button>
      </header>
      <section class="modal-card-body p-4">
        <slot></slot>
      </section>
      <footer class="modal-card-foot has-text-right">
        <!-- <button class="button is-danger" @click="accept">Aceptar</button> -->
        <button class="button" @click="closeModal">
          <span v-if="textClose !== ''">{{ this.textClose }}</span>
          <span v-else>Cancelar</span>
        </button>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      open: false
    }
  },
  methods: {
    closeModal() {
      this.open = false
      this.$emit("closeModal")
    }
  },
  props: {
    openModal: Boolean,
    title: {
      type: String,
      required: true
    },
    textClose: {
      type: String,
      default: ""
    }
  },
  watch: {
    openModal: function() {
      if (this.openModal) {
        this.open = true
      }
    }
  }
}
</script>
