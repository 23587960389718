<template>
  <div>
    <form @submit.prevent="saveBankInfo" autocomplete="off">
      <div class="columns">
        <div class="column is-6 is-offset-3 is-size-4 has-text-centered">
          Confirma tus datos bancarios
        </div>
      </div>
      <div class="columns is-multiline">
        <div class="column is-6 is-offset-3">
          <div class="field">
            <label class="label required">
              Selecione el banco donde quieres que te desembolsemos tu crédito
            </label>
            <div class="select is-fullwidth" :class="{
      'is-danger': $v.bank.$error
    }">
              <select v-model="$v.bank.$model">
                <option value="">Seleccione una opción</option>
                <option v-for="bank in getBank" :key="bank.value" :value="bank.value">
                  {{ bank.label }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="column is-6 is-offset-3">
          <div class="field">
            <label class="label">
              La cuenta contiene esta secuencia
            </label>
            <div class="select is-fullwidth" :class="{
      'is-danger': $v.accountSequence.$error
    }">
              <select v-model="$v.accountSequence.$model">
                <option value="">Seleccione una opción</option>
                <option v-for="(account, index) in accounts" :key="index" :value="account">
                  {{ account }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="column is-6 is-offset-3">
          <div class="field">
            <label class="label required">Confirma tu cuenta</label>
            <div class="control has-icons-left">
              <input class="input" type="text" v-model.trim="$v.confirmAccount.$model"
                :class="{ 'is-danger': $v.confirmAccount.$error }" v-focus placeholder="**** **** **** ****" />
              <span class="icon is-small is-left">
                <font-awesome-icon icon="credit-card"></font-awesome-icon>
              </span>
            </div>
          </div>
        </div>
        <div class="column is-6 is-offset-3">
          <div class="field">
            <label class="label required">
              Tipo de cuenta
            </label>
            <div class="select is-fullwidth" :class="{
      'is-danger': $v.accountType.$error
    }">
              <select v-model="$v.accountType.$model">
                <option value="">Seleccione una opción</option>
                <option v-for="accountType in getAccountType" :key="accountType.value" :value="accountType.value">
                  {{ accountType.label }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="column is-6 is-offset-3">
          <div class="mb-4">
            Aceptas que te enviemos a tu email los siguientes contratos:
          </div>
          <div class="field">
            <label class="checkbox">
              <input type="checkbox" v-model="$v.costs.$model" />
              <span class="pl-2">
                Los costos y gastos
              </span>
            </label>
          </div>
          <div class="field">
            <label class="checkbox">
              <input type="checkbox" v-model="$v.aval.$model" />
              <span class="pl-2">
                Aval (Opcional)
              </span>
            </label>
          </div>
          <div class="field">
            <label class="checkbox">
              <input type="checkbox" v-model="$v.platform.$model" />
              <span class="pl-2">
                Plataforma (Opcional)
              </span>
            </label>
          </div>
          <div class="field">
            <label class="checkbox">
              <input type="checkbox" v-model="$v.promissory_note.$model" />
              <span class="pl-2">
                Mutuo y pagaré
              </span>
            </label>
          </div>
          <div class="field">
            <label class="checkbox">
              <input type="checkbox" v-model="$v.authority.$model" />
              <span class="pl-2">
                Poder irrevocable
              </span>
            </label>
          </div>
          <div class="field">
            <label class="checkbox">
              <input type="checkbox" v-model="$v.guarantee.$model" />
              <span class="pl-2">
                Garantías mobiliarias
              </span>
            </label>
          </div>
          <div class="field">
            <label class="checkbox">
              <input type="checkbox" v-model="$v.automatic_debit.$model" />
              <span class="pl-2">
                Débito automático
              </span>
            </label>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-6 is-offset-3 has-text-justify">
          Enviaremos una copia de los contratos antes de tu firma a tu correo
          para que los revises en detalle. Enviaremos un "link único de firma"
          que es para efectos legales tu firma electrónica.
        </div>
      </div>
      <div class="columns">
        <div class="column is-4 is-offset-4">
          <div class="buttons">
            <button class="button is-primary is-medium is-fullwidth" :disabled="isLoading || $v.$anyError">
              Continuar
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex"
import validatePermissions from "../../helpers/validatePermissions"
import { required, sameAs } from "vuelidate/lib/validators"
import {
  getBankAccounts,
  bankInformation,
  loanDisclaimerPost
} from "@/services/user"
export default {
  data() {
    return {
      isLoading: false,
      bank: "",
      accountSequence: "",
      confirmAccount: "",
      accountType: "",
      accounts: [],
      costs: true,
      aval: true,
      platform: true,
      promissory_note: true,
      authority: true,
      guarantee: true,
      automatic_debit: true
    }
  },
  beforeMount() {
    validatePermissions("DAT_BAN", "No tiene permisos para gestionar a un cliente en la pantalla de Datos Bancarios");
  },
  validations: {
    bank: { required },
    accountSequence: {},
    confirmAccount: { required },
    accountType: { required },
    costs: { required, sameAs: sameAs(() => true) },
    aval: { required },
    platform: { required },
    promissory_note: { required, sameAs: sameAs(() => true) },
    authority: { required, sameAs: sameAs(() => true) },
    guarantee: { required, sameAs: sameAs(() => true) },
    automatic_debit: { required, sameAs: sameAs(() => true) }
  },
  mounted() {
    let loanActive = this.getLoanForBankAccounts
    if (loanActive !== undefined) {
      this.addLoanActive(loanActive)
      this.getUserAccount()
    }
  },
  methods: {
    async getUserAccount() {
      try {
        let response = await getBankAccounts(
          this.getDocumentNumber,
          this.getLoanForBankAccounts.id
        )
        this.accounts = response.accounts
      } catch (error) {
        this.accounts = [
          { id: -1, value: "No se pudo obtener las cuentas del usuario" }
        ]
        this.$notyf.error(error)
      }
    },
    async saveBankInfo() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        let validAccount = true
        if (this.accounts.length > 0) {
          validAccount = new RegExp(
            `.*${this.accountSequence.slice(-4)}$`
          ).test(this.confirmAccount)
        }
        if (validAccount) {
          let loan = this.getApprovedOrApprovedAwaitingSignature
          if (loan !== undefined) {
            try {
              await bankInformation(this.getDocumentNumber, loan.id, {
                bank: this.bank,
                accountType: this.accountType,
                account: this.confirmAccount,
                automaticDebit: this.automatic_debit
              })

              let payload = {
                costsAndExpenses: this.costs,
                aval: this.aval,
                platform: this.platform,
                promissory: this.promissory_note,
                powerIrrevocable: this.authority,
                guarantees: this.guarantee
              }

              await loanDisclaimerPost(this.getDocumentNumber, payload)
              await this.updateStatus(this.getDocumentNumber)
              this.completeFormStep("bankInformation")
            } catch (error) {
              this.$notyf.error(
                "Ocurrio un error mientras se enviaba tu información."
              )
            }
          } else {
            this.$notyf.error(
              "No existe un prestamo con el estado 'Approved' o 'Approved awaiting siganture'"
            )
          }
        } else {
          this.$notyf.error(
            "No coincide la cuenta ingresada con la seleccionada"
          )
        }
      }
    },
    ...mapMutations({
      completeFormStep: "user/completeFormStep",
      addLoanActive: "user/addLoanActive"
    }),
    ...mapActions({
      updateStatus: "user/updateStatus"
    })
  },
  computed: {
    ...mapGetters({
      getBank: "user/getBank",
      getAccountType: "user/getAccountType",
      getDocumentNumber: "user/getDocumentNumber",
      getLoanForBankAccounts: "user/getLoanForBankAccounts",
      getApprovedOrApprovedAwaitingSignature:
        "user/getApprovedOrApprovedAwaitingSignature",
      getApprovedAwaitingSignature: "user/getApprovedAwaitingSignature"
    })
  }
}
</script>

<style></style>
