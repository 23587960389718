export default blob => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    return new Promise(resolve => {
        reader.onloadend = () => {
        let dataUrl = reader.result;
        let base64 = dataUrl.split(',')[1];
        resolve(base64);
        };
    });
};