<template>
  <div>
    <form @submit.prevent="saveLaboralInformation" autocomplete="off">
      <div class="columns">
        <div class="column is-4 is-offset-4">
          <div class="field">
            <label class="label required">Tipo de empleo</label>
            <div
              class="select is-fullwidth"
              :class="{
                'is-danger': $v.laboralInformation.jobType.$error
              }"
            >
              <select
                v-model="$v.laboralInformation.jobType.$model"
                @change="$v.$reset"
              >
                <option value="">Seleccione una opción</option>
                <option
                  v-for="jobType in getJobType"
                  :key="jobType.value"
                  :value="jobType.value"
                >
                  {{ jobType.label }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="columns is-multiline">
        <div
          class="column is-4"
          v-show="isJobTypeEmployeed() || isJobTypeTemporaryEmployee()"
        >
          <div class="field">
            <label class="label required">Compañía donde trabajas</label>
            <div class="control">
              <input
                class="input"
                type="text"
                v-model.trim="$v.laboralInformation.company.$model"
                :class="{ 'is-danger': $v.laboralInformation.company.$error }"
                v-focus
              />
            </div>
          </div>
        </div>
        <div class="column is-4" v-show="isJobTypeIndependent()">
          <div class="field">
            <label class="label"
              >NIT de la empresa (Sólo si eres emprendedor)</label
            >
            <div class="control">
              <input
                class="input"
                type="text"
                v-model.trim="$v.laboralInformation.nit.$model"
                :class="{ 'is-danger': $v.laboralInformation.nit.$error }"
                v-focus
              />
            </div>
          </div>
        </div>
        <div class="column is-4" v-show="isJobTypeIndependent()">
          <div class="field">
            <label class="label required">Ocupación</label>
            <div
              class="select is-fullwidth"
              :class="{
                'is-danger': $v.laboralInformation.economicActivity.$error
              }"
            >
              <select v-model="$v.laboralInformation.economicActivity.$model">
                <option value="">Seleccione una opción</option>
                <option
                  v-for="economicActivity in getEconomicActivity"
                  :key="economicActivity.value"
                  :value="economicActivity.value"
                >
                  {{ economicActivity.label }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="column is-4" v-show="isJobTypeAllesExceptUnemployed()">
          <div class="field">
            <label class="label required">Sector económico</label>
            <div
              class="select is-fullwidth"
              :class="{
                'is-danger': $v.laboralInformation.economicSector.$error
              }"
            >
              <select v-model="$v.laboralInformation.economicSector.$model">
                <option value="">Seleccione una opción</option>
                <option
                  v-for="economicSector in getEconomicSector"
                  :key="economicSector.value"
                  :value="economicSector.value"
                >
                  {{ economicSector.label }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="column is-4" v-show="isJobTypeIndependent()">
          <div class="field">
            <label class="label">Otros ingresos</label>
            <div
              class="select is-fullwidth"
              :class="{
                'is-danger': $v.laboralInformation.otherIncome.$error
              }"
            >
              <select v-model="$v.laboralInformation.otherIncome.$model">
                <option value="">Seleccione una opción</option>
                <option
                  v-for="otherIncome in getOtherIncome"
                  :key="otherIncome.value"
                  :value="otherIncome.value"
                >
                  {{ otherIncome.label }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="column is-4" v-show="isJobTypeAllesExceptUnemployed()">
          <div class="field">
            <label class="label required"
              >Banco donde te consignan la nómina/ingresos</label
            >
            <div
              class="select is-fullwidth"
              :class="{
                'is-danger': $v.laboralInformation.bank.$error
              }"
            >
              <select v-model="$v.laboralInformation.bank.$model">
                <option value="">Seleccione una opción</option>
                <option
                  v-for="bank in getBank"
                  :key="bank.value"
                  :value="bank.value"
                >
                  {{ bank.label }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="column is-4" v-show="isJobTypeAllesExceptUnemployed()">
          <div class="field">
            <label class="label required">Salario/ingreso mensual</label>
            <div class="control has-icons-left">
              <input
                class="input"
                type="text"
                v-model.trim="$v.laboralInformation.monthlySalary.$model"
                :class="{
                  'is-danger': $v.laboralInformation.monthlySalary.$error
                }"
                v-focus
              />
              <span class="icon is-small is-left">
                <font-awesome-icon icon="dollar-sign"></font-awesome-icon>
              </span>
            </div>
          </div>
        </div>
        <div class="column is-4" v-show="isJobTypeAllesExceptUnemployed()">
          <div class="field">
            <label class="label required"
              >¿Cómo recibes la mayor parte de tus ingresos?</label
            >
            <div
              class="select is-fullwidth"
              :class="{
                'is-danger': $v.laboralInformation.paymentMethod.$error
              }"
            >
              <select v-model="$v.laboralInformation.paymentMethod.$model">
                <option value="">Seleccione una opción</option>
                <option
                  v-for="paymentMethod in getPaymentMethod"
                  :key="paymentMethod.value"
                  :value="paymentMethod.value"
                >
                  {{ paymentMethod.label }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="column is-4" v-show="isJobTypeAllesExceptUnemployed()">
          <div class="field">
            <label class="label required">Periodicidad de pago</label>
            <div
              class="select is-fullwidth"
              :class="{
                'is-danger': $v.laboralInformation.paymentPeriodicity.$error
              }"
            >
              <select v-model="$v.laboralInformation.paymentPeriodicity.$model">
                <option value="">Seleccione una opción</option>
                <option
                  v-for="paymentPeriodicity in getPaymentPeriodicity"
                  :key="paymentPeriodicity.value"
                  :value="paymentPeriodicity.value"
                >
                  {{ paymentPeriodicity.label }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="columns" v-show="laboralInformation.jobType != ''">
        <div class="column is-4 is-offset-4">
          <div class="buttons">
            <button
              class="button is-primary is-medium is-fullwidth"
              :disabled="isLoading || $v.$anyError"
            >
              Guardar
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapMutations, mapGetters, mapActions } from "vuex"
import validatePermissions from "../../helpers/validatePermissions"
import { required, minValue } from "vuelidate/lib/validators"
export default {
  data() {
    return {
      laboralInformation: {
        jobType: "",
        company: "",
        economicActivity: "",
        bank: "",
        monthlySalary: "",
        paymentMethod: "",
        economicSector: "",
        nit: "",
        paymentPeriodicity: "",
        otherIncome: ""
      },
      isLoading: false
    }
  },
  validations() {
    return this.rules
  },
  beforeMount(){
    validatePermissions("REFE_LAB","No tiene permisos para gestionar a un cliente en la pantalla de Referencia Laboral");
  },
  mounted() {
    this.loadInitial()
  },
  methods: {
    loadInitial() {
      let info = this.getLaboralInformation
      if (info.jobType !== -1) {
        for (let i in info) {
          if (info[i] === 0) {
            info[i] = ""
          }
        }
        this.laboralInformation = info
      }
    },
    async saveLaboralInformation() {
      this.$v.laboralInformation.$touch()
      if (!this.$v.laboralInformation.$invalid) {
        this.isLoading = true
        try {
          this.laboralInformation.monthlySalary = parseInt(
            this.laboralInformation.monthlySalary,
            10
          )
          await this.postLaboralInformation({
            documentNumber: this.getDocumentNumber,
            payload: this.laboralInformation
          })
          await this.getClient({
            value: this.getDocumentNumber,
            method: "id"
          })
          this.completeFormStep("laboralInformation")
        } catch (error) {
          this.$notyf.error("Error al guardar la información laboral.")
        }
        this.isLoading = false
      }
    },
    isJobTypeEmployeed() {
      return this.laboralInformation.jobType == 50
    },
    isJobTypeIndependent() {
      return this.laboralInformation.jobType == 52
    },
    isJobTypeTemporaryEmployee() {
      return this.laboralInformation.jobType == 51
    },
    isJobTypeAllesExceptUnemployed() {
      return (
        this.laboralInformation.jobType == 50 ||
        this.laboralInformation.jobType == 51 ||
        this.laboralInformation.jobType == 52 ||
        this.laboralInformation.jobType == 53 ||
        this.laboralInformation.jobType == 1307
      )
    },
    ...mapMutations({
      setPersonalInformation: "user/setPersonalInformation",
      completeFormStep: "user/completeFormStep"
    }),
    ...mapActions({
      postLaboralInformation: "user/postLaboralInformation",
      getClient: "user/getClient"
    })
  },
  computed: {
    rules() {
      let rules = {
        jobType: {},
        economicSector: { required },
        bank: { required },
        monthlySalary: {
          required,
          min: minValue(this.getMinMonthSalary(this.jobTypeSelected)) || 0
        },
        paymentMethod: {
          required
        },
        paymentPeriodicity: {
          required
        },
        company: {},
        nit: {},
        economicActivity: {},
        otherIncome: {}
      }
      if (
        this.laboralInformation.jobType == 50 ||
        this.laboralInformation.jobType == 51
      ) {
        rules = { ...rules, ...{ company: { required } } }
      }
      if (this.laboralInformation.jobType == 52) {
        rules = {
          ...rules,
          ...{
            economicActivity: { required },
            otherIncome: { required }
          }
        }
      }
      return { laboralInformation: rules }
    },
    jobTypeSelected() {
      let jobType = this.getJobType.find(
        jobType => jobType.value == this.laboralInformation.jobType
      )
      return jobType ? jobType.label : ""
    },
    ...mapGetters({
      getLaboralInformation: "user/getLaboralInformation",
      getDocumentNumber: "user/getDocumentNumber",
      getJobType: "user/getJobType",
      getEconomicSector: "user/getEconomicSector",
      getBank: "user/getBank",
      getPaymentPeriodicity: "user/getPaymentPeriodicity",
      getPaymentMethod: "user/getPaymentMethod",
      getEconomicActivity: "user/getEconomicActivity",
      getOtherIncome: "user/getOtherIncome",
      getMinMonthSalary: "user/getMinMonthSalary"
    })
  }
}
</script>

<style></style>
