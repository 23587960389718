<template>
  <div class="mt-2">
      <div class="card text-center">
        <div class="card-header">
          {{username}}
        </div>
        <div class="card-body">
        <div v-show="isPublishing" class="alert alert-primary" role="alert">
          Publicando video
        </div>
        <div v-if="showSpinner" class="d-flex justify-content-center">
          <div class="spinner-border text-danger" role="status">
            <span class="sr-only">Cargando...</span>
          </div>
        </div>
        <!-- v-if="hasVideo" -->
        <video ref="videoContainer" type="video" playsinline autoplay :aspect="aspectRatio" :muted="mute"/>
        <div v-show="!hasVideo" class="alert alert-danger" role="alert">
          ¡No hay fuente de video disponible!
        </div>
        </div>
        <div class="card-footer text-muted">
          <div class="btn-group btn-group-sm mx-1" v-if="showHostActions">
            <button class="btn btn-primary" variant="outline-info" @click="capture">Captura</button>
            <button class="btn btn-primary" variant="outline-info" @click="$emit('flip-camera')">Cambiar camara</button>
          </div>
          <div class="btn-group btn-group-sm mx-1" v-if="showMuteButton">
            <button class="btn btn-primary" @click="$emit('toggle-mute')">{{ isMuted ? 'Activar Sonido' : 'Silenciar' }}</button>
            <button class="btn btn-primary" @click="$emit('hangup')">Colgar</button>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
export default {
    props: {
    isPublishing: Boolean,
    hasVideo: {
      type: Boolean,
      default: true,
    },
    showSpinner: {
      type: Boolean,
      default: true,
    },
    username: {
      type: String,
      required: true,
      default: "",
    },
    showHostActions: Boolean,
    showMuteButton: Boolean,
    isMuted: {
      type: Boolean,
      default: false
    },
    mute: Boolean,
  },
  data() {
    return {
      aspectRatio: '16by9',
      height: null,
      width: null,
    }
  },
  methods: {
    onChange(value) {
      this.$emit('input', value);
      this.value = value;
    },
    checkRatio() {
      const video = this.$refs.videoContainer && this.$refs.videoContainer.children[0];
      if (video) {
        video.addEventListener('playing', () => {
          this.height = video.videoHeight;
          this.width = video.videoWidth;
          const ratio = video.videoWidth / video.videoHeight;
          switch (ratio) {
            case 4 / 3:
              this.aspectRatio = '4by3';
              break;
            case 21 / 9:
              this.aspectRatio = '21by9';
              break;
            default:
              this.aspectRatio = '16by9';
              break;
          }
        }, {once: true}); 
      }
    },
    capture() {
      const video = this.$refs.videoContainer
      if (video) {
        const fileName = `${(new Date()).toISOString()}-${this.username}.png`;
        this.$emit('screenshot', { video, fileName, height: this.height, width: this.width });
      }
    }
  },
  mounted() {
    this.checkRatio();
  },
}
</script>