<template>
  <div class="col-md-6">
      <div class="form-group p-2">
        <label>{{ label }}</label>
        <input v-model="username" type="text" class="form-control form-control-sm" @input="onChange" disabled/>
      </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    user: {
      type: String,
      required: true,
    },
    disabled: Boolean,
  },
  data() {
    return {
      username: "",
    }
  },
  mounted(){
    this.username = this.value
  },
  methods: {
    onChange(value) {
      this.$emit('input', { user: this.user , value: value.target.value});
    },
  }
}
</script>

<style>

</style>