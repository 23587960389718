<template>
  <div>
    <form @submit.prevent="saveUser" autocomplete="off">
      <div class="columns">
        <div class="column is-4 is-offset-4">
          <div class="buttons">
            <button
              class="button is-primary is-medium is-fullwidth"
              @click.prevent="generateOTP"
            >
              Generar OTP
            </button>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-half is-offset-one-quarter">
          <div class="field">
            <label class="label required">Ingresar OTP</label>
            <div class="control">
              <input
                class="input"
                type="text"
                v-model.trim="$v.otpValue.$model"
                :class="{ 'is-danger': $v.otpValue.$error }"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="columns" v-show="otpSent">
        <div class="column is-4 is-offset-4">
          <div class="buttons">
            <button
              class="button is-primary is-medium is-fullwidth"
              :disabled="isLoading || $v.$anyError"
              @click.prevent="verifyOTP"
            >
              Validar OTP
            </button>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-half is-offset-one-quarter">
          <div class="field">
            <label class="checkbox">
              <input type="checkbox" v-model="checkedTermsCond" />
              Acepto los
              <a href="#" @click.prevent="openTermsCond = true"
                >Términos y condiciones</a
              >
            </label>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-4 is-offset-4">
          <div class="buttons">
            <button
              class="button is-danger is-medium is-fullwidth"
              :disabled="!checkedTermsCond || !otpValidated || isLoading"
            >
              Registar
            </button>
          </div>
        </div>
      </div>
    </form>
    <div :class="{ 'is-active': openTermsCond }" class="modal">
      <div class="modal-background"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Términos y condiciones</p>
          <button class="delete" @click="openTermsCond = false"></button>
        </header>
        <section class="modal-card-body">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Recusandae
          optio quo blanditiis eum sit eos, ipsam vero nemo pariatur nostrum
          perferendis. Dolores voluptatem, incidunt ipsa delectus eum numquam
          facilis veritatis.
        </section>
        <footer class="modal-card-foot">
          <button class="button is-primary" @click="acceptTermsCond()">
            Acepto
          </button>
          <button class="button is-danger" @click="openTermsCond = false">
            Cerrar
          </button>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex"
import { required, numeric } from "vuelidate/lib/validators"
import validatePermissions from "../../helpers/validatePermissions"
import { sendOTP, validateOTP, createClient } from "@/services/user"
export default {
  data() {
    return {
      otpValue: "",
      otpValidated: false,
      checkedTermsCond: false,
      openTermsCond: false,
      otpSent: false,
      isLoading: false
    }
  },
  validations: {
    otpValue: {
      required,
      numeric
    }
  },
  beforeMount(){
    validatePermissions("OTP","No tiene permisos para gestionar a un cliente en la pantalla de OTP");
  },
  methods: {
    acceptTermsCond() {
      this.openTermsCond = false
      this.checkedTermsCond = true
    },
    async generateOTP() {
      this.isLoading = true
      try {
        await sendOTP(this.userPhone)
        this.otpSent = true
      } catch (error) {
        this.$notyf.error(error)
      }
      this.isLoading = false
    },
    async verifyOTP() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.isLoading = true
        try {
          let response = await validateOTP(this.userPhone, this.otpValue)
          if (response.correctCode) {
            this.otpValidated = true
            this.otpSent = false
            this.$notyf.success("OTP verificado")
          } else {
            this.$notyf.error("El OTP ingresado no es válido")
          }
        } catch (error) {
          this.$notyf.error(error)
        }
        this.isLoading = false
      }
    },
    async saveUser() {
      let basicInfo = this.basicInfo
      let ubicaSelected = this.ubicaSelected

      let payload = {
        cc: basicInfo.cc,
        givenname: basicInfo.givenname,
        surname: basicInfo.surname,
        lastname: basicInfo.lastname,
        phone: {
          value: ubicaSelected.phone.phone,
          id: ubicaSelected.phone.id
        },
        email: {
          value: ubicaSelected.email.email,
          id: ubicaSelected.email.id
        }
      }
      this.isLoading = true
      try {
        await createClient(payload)
        await this.getClient({
          value: basicInfo.cc,
          method: "id"
        })
        this.completeFormStep("generateOTP")
      } catch (error) {
        this.$notyf.error(error)
      }
      this.isLoading = false
    },
    ...mapMutations({
      completeFormStep: "user/completeFormStep"
    }),
    ...mapActions({
      getClient: "user/getClient"
    })
  },
  computed: {
    ...mapGetters({
      userPhone: "user/getPhone",
      basicInfo: "user/getBasicInfo",
      ubicaSelected: "user/getUbicaSelected"
    })
  }
}
</script>

<style></style>
