<template>
  <div class="validate">
    <!-- <div v-if="!isErrorComponent && !isError">
      <Telephant :hostProp="true" :agentProp="getUsername" :userProp="getPhone"/>
    </div>
    <div v-if="isErrorComponent">
      <div class="notification is-danger is-light">
        {{ error }}
      </div>
    </div> -->
    Pendiente por validación de identidad
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex"
import validatePermissions from "../../helpers/validatePermissions"
import Telephant from './../../components/user/janus/Telephant'

export default {
  components: {
    Telephant
  },
  data() {
    return {
      isLoading: false,
      error: "",
      //loanActive: null
    }
  },
  beforeMount() {
    validatePermissions("EID", "No tiene permisos para gestionar a un cliente en la pantalla de ElectronicId");
  },
  async mounted() {
    try {
      if (!this.isError) await this.getProfileImage(this.getDocumentNumber)
    } catch (err) {
      console.error(err);
    }
    //this.loanActive = this.loanPendingIdValidation
    // if (this.loanActive === null) {
    //   this.error = "Error en el estado del Credito"
    // } else {
    //   this.addLoanActive(this.loanActive)
    // }
  },
  computed: {
    ...mapGetters({
      loanPendingIdValidation: "user/getPendingIdValidation",
      getPhone: "user/getPhone",
      getUsername: "auth/getUsername",
      getDocumentNumber: "user/getDocumentNumber",
      getPermission: "auth/getPermission",
      isError: "form/isError"
    }),
    isErrorComponent() {
      return this.error === "" ? false : true
    }
  },
  methods: {
    ...mapActions({
      getProfileImage: "user/getProfileImage"
    }),
    ...mapMutations({
      addLoanActive: "user/addLoanActive",
      setError: "form/setError"
    }),
  }
}
</script>

<style>
.validate {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 50px;
}
</style>
