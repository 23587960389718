const formats = {
  names: {
    match: /[^a-z.\u00A8-\u00FF\s]/i,
    replace: "",
    upper: true
  },
  email: { match: /\s+/i, replace: "" }
}

export const validatorNames = value => {
  return !formats.names.match.test(value)
}
