<template>
  <div>
    <form @submit.prevent="savePersonalInformation" autocomplete="off">
      <div class="columns">
        <div class="column">
          <div class="field">
            <label class="label required"
              >Fecha de expedición del documento de identidad</label
            >
            <div
              class="control"
              :class="{ 'is-danger': $v.expeditionDate.$error }"
            >
              <date-picker
                v-model.trim="$v.expeditionDate.$model"
                type="date"
                format="DD-MM-YYYY"
                lang="es"
                :editable="false"
                input-class="input date_picker"
                valueType="format"
              ></date-picker>
            </div>
          </div>
        </div>
        <div class="column">
          <div class="field">
            <label class="label required">Fecha de nacimiento</label>
            <div class="control" :class="{ 'is-danger': $v.birthdate.$error }">
              <date-picker
                v-model.trim="$v.birthdate.$model"
                type="date"
                format="DD-MM-YYYY"
                lang="es"
                :editable="false"
                input-class="input date_picker"
                valueType="format"
              ></date-picker>
            </div>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <div class="field">
            <label class="label required">Sexo</label>
            <div
              class="select is-fullwidth"
              :class="{ 'is-danger': $v.gender.$error }"
            >
              <select v-model="$v.gender.$model">
                <option value="">Seleccione una opción</option>
                <option
                  v-for="gender in getGenders"
                  :key="gender.value"
                  :value="gender.value"
                >
                  {{ gender.label }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="column">
          <div class="field">
            <label class="label required"
              >Cuántas personas dependen de ti económicamente</label
            >
            <div
              class="select is-fullwidth"
              :class="{ 'is-danger': $v.dependents.$error }"
            >
              <select v-model="$v.dependents.$model">
                <option value="">Seleccione una opción</option>
                <option value="Ninguna">Ninguna</option>
                <option value="Entre 1 y 3">Entre 1 y 3</option>
                <option value="Mas de 3">Mas de 3</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <div class="field">
            <label class="label required">Nivel de estudios</label>
            <div
              class="select is-fullwidth"
              :class="{ 'is-danger': $v.educationalLevel.$error }"
            >
              <select v-model="$v.educationalLevel.$model">
                <option value="">Seleccione una opción</option>
                <option
                  v-for="educationLevel in getEducationalLevel"
                  :key="educationLevel.value"
                  :value="educationLevel.value"
                >
                  {{ educationLevel.label }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="column">
          <div class="field">
            <label class="label required">Estado civil</label>
            <div
              class="select is-fullwidth"
              :class="{ 'is-danger': $v.maritalStatus.$error }"
            >
              <select v-model="$v.maritalStatus.$model">
                <option value="">Seleccione una opción</option>
                <option
                  v-for="maritalStatus in getMaritalStatus"
                  :key="maritalStatus.value"
                  :value="maritalStatus.value"
                >
                  {{ maritalStatus.label }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-4 is-offset-4">
          <div class="buttons">
            <button
              class="button is-primary is-medium is-fullwidth"
              :disabled="$v.$anyError"
            >
              Siguiente
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex"
import { required } from "vuelidate/lib/validators"
import validatePermissions from "../../helpers/validatePermissions"
import DatePicker from "vue2-datepicker"
import "vue2-datepicker/locale/es"

const date = new Date()
const minDate = new Date(date.getFullYear() - 80, 1, 1)
const maxDate = new Date().setFullYear(date.getFullYear() - 18)

const convertDate = fecha => {
  let year = fecha.slice(6, 10)
  let month = fecha.slice(3, 5)
  let day = fecha.slice(0, 2)
  return new Date(`${year}-${month}-${day}`)
}

export default {
  data() {
    return {
      expeditionDate: "",
      birthdate: "",
      gender: "",
      dependents: "",
      educationalLevel: "",
      maritalStatus: ""
    }
  },
  validations: {
    expeditionDate: {
      required,
      max: fecha => {
        if (fecha) {
          return convertDate(fecha).getTime() > date ? false : true
        } else {
          return true
        }
      },
      min: fecha => {
        if (fecha) {
          return convertDate(fecha).getTime() < minDate.getTime() ? false : true
        } else {
          return true
        }
      }
    },
    birthdate: {
      required,
      max: fecha => {
        if (fecha) {
          return convertDate(fecha).getTime() > maxDate ? false : true
        } else {
          return true
        }
      },
      min: fecha => {
        if (fecha) {
          return convertDate(fecha).getTime() < minDate.getTime() ? false : true
        } else {
          return true
        }
      }
    },
    gender: { required },
    dependents: { required },
    educationalLevel: { required },
    maritalStatus: { required }
  },
  components: {
    DatePicker
  },
  beforeMount(){
    validatePermissions("INFO_PER","No tiene permisos para gestionar a un cliente en la pantalla de Información Personal");
  },
  mounted() {
    this.loadInitial()
  },
  methods: {
    loadInitial() {
      let personalInformation = this.getPersonalInformation
      this.birthdate = personalInformation.birthdate || ""
      this.expeditionDate = personalInformation.expeditionDate || ""
      this.gender =
        personalInformation.genderId == -1
          ? ""
          : personalInformation.genderId || ""
      this.dependents = personalInformation.dependents || ""
      this.educationalLevel = personalInformation.educationalLevel || ""
      this.maritalStatus = personalInformation.maritalStatus || ""
    },
    savePersonalInformation() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        let personalInformation = this.getPersonalInformation
        personalInformation.birthdate = this.birthdate
        personalInformation.expeditionDate = this.expeditionDate
        personalInformation.genderId = this.gender
        personalInformation.dependents = this.dependents
        personalInformation.educationalLevel = this.educationalLevel
        personalInformation.maritalStatus = this.maritalStatus
        this.setPersonalInformation(personalInformation)
        this.completeFormStep("personalInformation")
      }
    },
    ...mapMutations({
      setPersonalInformation: "user/setPersonalInformation",
      completeFormStep: "user/completeFormStep"
    })
  },
  computed: {
    ...mapGetters({
      getPersonalInformation: "user/getPersonalInformation",
      getGenders: "user/getGenderSelecters",
      getEducationalLevel: "user/getEducationalLevelSelecters",
      getMaritalStatus: "user/getMaritalStatus"
    })
  }
}
</script>

<style></style>
