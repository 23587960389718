<template>
  <div>
      <div v-if="formVisible==='file'">
        <div class="columns">
          <div class="column is-half is-offset-one-quarter">
            <div class="file is-centered has-name is-boxed">
              <label class="file-label">
                <input class="file-input" @change="previewFiles" type="file" name="resume" accept="application/pdf">
                <span class="file-cta">
                  <span class="file-icon">
                    <font-awesome-icon icon="upload"></font-awesome-icon>
                  </span>
                  <span class="file-label">
                    Seleccione extracto
                  </span>
                </span>
                <span class="file-name" v-if="filesLength > 0" >
                  <div v-for="row in files" :key="row.name">
                    {{ row.name }}
                  </div>
                </span>
              </label>
            </div>
          </div>
        </div>
        <div class="columns">
          <div class="column is-half is-offset-one-quarter">
            <div class="buttons is-centered">
              <button
                class="button is-primary is-fullwidth"
                :disabled="isLoading || filesLength===0"
                @click="SendFormFiles()"
              >
                Enviar
              </button>
            </div>
          </div>
        </div>
      </div>
      <div v-if="formVisible==='form'">
        <form @submit.prevent="ejectPolicy" autocomplete="off">
          <div class="columns">
            <div class="column is-half is-offset-one-quarter">
              <div class="field">
                <label class="label required">Salario/ingreso mensual</label>
                <div class="control has-icons-left">
                  <input 
                    type="text"
                    class="input" 
                    :class="{
                      'is-danger': $v.monthlySalary.$error
                    }" 
                    placeholder="Salario mensual" 
                    @input="monthlySalaryFormat"
                    v-model.trim="$v.monthlySalary.$model"
                    v-focus
                  />
                  <span class="icon is-small is-left">
                    <font-awesome-icon icon="dollar-sign"></font-awesome-icon>
                  </span>
                </div>
              </div>
              
            </div>
          </div>
          <div class="columns">
            <div class="column is-half is-offset-one-quarter">
              <div class="buttons is-centered">
                <button
                  class="button is-primary is-fullwidth"
                  :disabled="isLoading || $v.monthlySalary.$error"
                >
                  Ejecutar politica
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
  </div>
</template>

<script>
import { mapActions , mapGetters} from 'vuex'
import blobToBase64 from '../../helpers/blobToBase64'
import { required } from "vuelidate/lib/validators"
import moneyFormat from "@/helpers/moneyFormat"
export default {
  data(){
    return {
      isLoading: false,
      formVisible: 'file',
      files: null,
      monthlySalary: null
    }
  },
  validations(){
    return {
      monthlySalary: {
        required,
      },
    }
  },
  methods:{
    ...mapActions({
      SendFiles: "user/SendFiles",
      sendBankStatementsPolicy: "user/sendBankStatementsPolicy",
      getClient: "user/getClient"
    }),
    previewFiles(event){
      this.files = event.target.files
    },
    monthlySalaryFormat(){
      let value = moneyFormat(this.monthlySalary.replaceAll('.',''),false);
      this.monthlySalary = value === "NaN" 
        ? this.monthlySalary.substring(0, this.monthlySalary.length-1)
        : value;
    },
    async SendFormFiles(){
      try {
        this.isLoading = true;
        await Promise.all(Array.from(this.files)
          .map(async (item) => {

            let base64 = await blobToBase64(item);
            await this.SendFiles({ 
              file: {
                fileContent: base64,
                fileFormat: "pdf",
                fileName: item.name,
                mediaType: item.type,
                fileDescription: "Extracto"
              },
              documentNumber: this.getDocumentNumber
            });
            this.$notyf.success("Documento cargado satisfactoriamente.")
          }
        ))
        this.isLoading = false;
        this.formVisible = 'form'
      } catch (err) {
        this.isLoading = false;
        this.$notyf.error("Error al enviar extractos")
      }
    },
    async ejectPolicy(){
      try {
        this.isLoading = true;
        await this.sendBankStatementsPolicy({
          loanId: this.getloanActive.id,
          documentNumber: this.getDocumentNumber,
          payload: { monthlySalary: this.monthlySalary.replaceAll('.','') }
        })
        this.monthlySalary = null
        await this.getClient({
          value: this.getDocumentNumber,
          method: "id"
        })
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        this.$notyf.error(`[${error.code}] ${error.message}`)
      }
    }
  },
  computed: {
    ...mapGetters({
      getDocumentNumber: "user/getDocumentNumber",
      getloanActive: "user/getloanActive",
    }),
    filesLength(){
      return this.files ? this.files.length : 0
    }
  }
}
</script>

<style>

</style>