<template>
    <div class="container">
      <form autocomplete="off" @submit.prevent="searchUser">
        <div class="columns">
          <div class="column py-1 px-1">
            <div class="select is-fullwidth">
              <select v-if="rol === 'IV_DF'" v-model="loginMethod">
                <option
                  v-for="method in optionsMethodsIv"
                  :key="method.value"
                  :value="method.value"
                >
                  {{ method.label }}
                </option>
              </select>

              <select v-else v-model="loginMethod">
                <option
                  v-for="method in optionsMethods"
                  :key="method.value"
                  :value="method.value"
                >
                  {{ method.label }}
                </option>
              </select>
            </div>
          </div>
          <div v-if="rol === 'IV_DF'" class="column py-1 px-1">
            <div class="control">
              <input
                class="input"
                type="text"
                v-model="documentNumberSearched"
                v-focus
                placeholder="Ingrese..."
                autofocus
              />
            </div>
          </div>
          <div v-else class="column py-1 px-1">
            <div class="control">
              <input
                class="input"
                type="text"
                v-model.trim="$v.documentNumberSearched.$model"
                :class="{ 'is-danger': $v.documentNumberSearched.$error }"
                v-focus
                placeholder="Ingrese..."
                autofocus
              />
            </div>
          </div>
          <div class="column py-1 px-1">
            <button class="button is-fullwidth is-link is-outlined" :disabled="isLoading || $v.$anyError">
              Buscar
            </button>
          </div>
        </div>
      </form>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import { required, numeric } from "vuelidate/lib/validators"
import validatePermissions from "../../helpers/validatePermissions"
export default {
  data() {
    return {
      documentNumberSearched: "",
      isLoading: false,
      loginMethod: "id",
      rol: "",
      optionsMethods: [
        {
          label: "Cédula de ciudadanía",
          value: "id"
        },
        {
          label: "Número de celular",
          value: "phone"
        }
      ],
      optionsMethodsIv: [
        {
          label: "Cédula de ciudadanía",
          value: "id"
        },
        {
          label: "Número de celular",
          value: "phone"
        },
/*         {
          label: "UUID",
          value: "uuid"
        } */
      ]
    }
  },
  validations: {
    documentNumberSearched: { required, numeric },
    loginMethod: { required }
  },
  beforeMount(){
    validatePermissions("IV_DF","No tiene permisos");
  },
  mounted() {
    this.cleanData
    if (this.getPermission.slice(54, 59) === 'IV_DF') {
      this.rol = this.getPermission.slice(54, 59)
    }
  },
  methods: {
    async searchUser() {
      if (this.loginMethod === 'uuid') {
        try {
          let uuid = await this.getUuidInfo(this.documentNumberSearched)
          this.$router.push({
            name: "veriff",
            params:{
              codeUuid: this.documentNumberSearched,
              uuidData: uuid
            } })
        } catch (error) {
          this.$notyf.error(error.message)
        }
      } else {  
        this.$v.$touch()
        if (!this.$v.$invalid) {
          this.isLoading = true
          try {
            await this.getClient({
              value: this.documentNumberSearched,
              method: this.loginMethod
            })
          } catch (error) {
            this.$notyf.error(error)
          }
          this.isLoading = false
        }
      }
    },
    ...mapActions({
      cleanData: "user/cleanData",
      getClient: "user/getClient",
      getUuidInfo: "user/getUuidInfo"
    })
  },
  computed: {
    ...mapGetters({
      getPermission: 'auth/getPermission'
    })
  },
}
</script>

<style>
  .container{
    max-width: 750px !important;
  }
</style>
