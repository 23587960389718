<template>
  <div>
    <ModalAlert
      :open="modalIncomigCall.modalActive"
      :variant="modalIncomigCall.variant"
      :title="modalIncomigCall.title"
      :message="modalIncomigCall.message"
      :accept="modalIncomigCall.accept"
      :acceptLabel="modalIncomigCall.acceptLabel"
      :cancel="modalIncomigCall.cancel"
      :cancelLabel="modalIncomigCall.cancelLabel"
      v-if="modalIncomigCall.modalActive"
    ></ModalAlert>
    <the-menu v-if="!isHost && !test" />
    <div v-if="!hasError" class="container-janus">
      <admin-form v-if="isHost" :videoId="this.videoId" @hangup="hangup" :test="test" v-on:clientImageId="setImageId"/>
      <dial-form 
        :test="test"
        v-show="!callInProgress" 
        :user-registered="userRegistered" 
        :calling="callRequested" 
        v-if="canShowDialForm" 
        :is-host="isHost" 
        :currentUsername="users[0]" 
        :toCallUsername="users[1]" 
        @register="registerUser" 
        @call="onCall"
        @changeUser="changeUser"
      />
      <div v-else class="d-flex justify-content-center">
        <div class="spinner-border text-danger" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
      <div class="row mt-3" v-show="callRequested">
        <div class="col-md-6">
          <video-container 
            @toggle-mute="onMuteToggle" mute 
            @hangup="hangup" 
            :is-muted="isLocalVideoMuted" 
            :show-mute-button="true" 
            :username="registeredUsername" 
            :is-publishing="isPublishing" 
            :show-spinner="false" 
            :has-video="!noLocalVideo" 
            ref="localVideoContainer"
          />
        </div>
        <div class="col-md-6">
          <video-container 
            @screenshot="onScreenShot" 
            @flip-camera="onFlipCamera" 
            :show-host-actions="isHost" 
            :username="fromUsername" 
            v-show="callInProgress" 
            :show-spinner="waitingForRemoteVideo" 
            :has-video="!noRemoteVideo" 
            ref="remoteVideoContainer"
          />
        </div>
      </div>

      <div class="row justify-content-md-center" v-if="screenshots.length > 0">
        <div class="col-md-6 mx-auto my-3">
          <div class="card text-center">
            <div class="card-body">
            <div id="carouselExampleIndicators" class="carousel slide" data-interval="false">
              <ul class="carousel-indicators">
                <li 
                  data-target="#carouselExampleIndicators" 
                  v-for="(item, idx) in screenshots"
                  :key="idx"
                  :data-slide-to="idx"
                  :class="{ active: idx===0 }"
                >
                </li>
              </ul>
              <div class="carousel-inner">
                <div 
                  v-for="(item, id) in screenshots"
                  class="carousel-item"
                  :class="{ active: id===0 }"
                  :key="item.id"
                  >
                    <img
                    class="d-block img-fluid w-100"
                    style="cursor: pointer;"
                    :width="imgWidth"
                    :height="imgHeight"
                    :src="item.url"
                    @click="downloadImage(item.url, item.fileName)"/>
                    <div class="carousel-caption d-md-block">
                      <div class="row">
                        <div class="col px-1">
                          <div class="form-check">
                            <input type="checkbox" 
                              class="form-check-input" 
                              :id="`profileCheck_${id}`" 
                              :disabled="(selectedProfile && !item.profile) || (item.face || item.obverse || item.reverse) "
                              v-model="item.profile" >
                            <label class="form-check-label" :for="`profileCheck_${id}`">Perfil</label>
                          </div>
                        </div>
                        <div class="col px-1">
                          <div class="form-check">
                            <input type="checkbox" 
                              class="form-check-input" 
                              :id="`profileCheck_${id}`" 
                              :disabled="(selectedFace && !item.face) || (item.profile || item.obverse || item.reverse)"
                              v-model="item.face" >
                            <label class="form-check-label" :for="`faceCheck_${id}`">Cara</label>
                          </div>
                        </div>
                        <div class="col px-1">
                          <div class="form-check">
                            <input type="checkbox" 
                              class="form-check-input" 
                              :id="`obverseCheck_${id}`" 
                              :disabled="(selectedObverse && !item.obverse) || (item.face || item.profile || item.reverse)"
                              v-model="item.obverse" >
                            <label class="form-check-label" :for="`obverseCheck_${id}`">Anverso</label>
                          </div>
                        </div>
                        <div class="col px-1">
                          <div class="form-check">
                            <input type="checkbox" 
                              class="form-check-input" 
                              :id="`reverseCheck_${id}`" 
                              :disabled="(selectedReverse && !item.reverse) || (item.face || item.profile || item.obverse)"
                              v-model="item.reverse" >
                            <label class="form-check-label" :for="`reverseCheck_${id}`">Reverso</label>
                          </div>
                        </div>
                      </div>                    
                    </div>
                </div> 
              </div>
              <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="sr-only">Previous</span>
              </a>
              <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="sr-only">Next</span>
              </a>
            </div>
            </div>
            <div class="card-footer text-muted">

              <button class="btn btn-outline-secondary" v-if="screenshots.length > 0" v-show="!selectedLength" :disabled="!selectedLength || uploadInProgress"  @click="onUpload">
                Enviar
              </button>

              <button class="btn btn-outline-primary" v-if="screenshots.length > 0"  v-show="selectedLength" :disabled="!selectedLength || uploadInProgress"  @click="onUpload">
                {{ uploadInProgress ? "Enviando.." : "Enviar" }}
              </button>

              <button class="btn btn-outline-danger" v-if="screenshots.length > 0"  @click="()=>screenshots=[]">Limpiar</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import Dial_form from "./Dial_form.vue"
import TheMenu from "@/components/TheMenu"
import Admin_form from "./Admin_form.vue"
import Video_container from "./Video_container.vue"
import { mapActions, mapGetters } from 'vuex'
import blobToBase64 from './../../../helpers/blobToBase64'
import ModalAlert from "@/components/UI/ModalAlert"
import { href } from "@/helpers/apiWhatsapp"
const server = "wss://video.telephant.co:8989/janus"
import imageToBucket from "@/helpers/ImageToBucket"

export const getQueryParamValue = name => {
  const urlParams = new URLSearchParams(window.location.search)
  return urlParams.get(name)
}


export default {
  props:{
    test:{
      type: Boolean,
      default: false,
      require: true
    },
    hostProp:{
      type: Boolean,
      default: false
    },
    agentProp:{
      type: String
    },
    userProp:{
      type: String
    }
  },
  components: {
      "dial-form": Dial_form,
      "video-container": Video_container,
      "admin-form": Admin_form,
      "the-menu": TheMenu,
      ModalAlert
  },
  data(){ 
    return {
      isHost: false,
      users: [],
      videoId: null,
      hasError: false,
      canShowDialForm: false,
      userRegistered: false,
      registeredUsername: "",
      cameraFacingMode: 'user',
      hadCall: false,
      callRequested: false,
      callInProgress: false,
      isPublishing: false,
      noLocalVideo: true,
      noRemoteVideo: true,
      waitingForRemoteVideo: false,
      fromUsername: "",
      screenshots: [],
      imgHeight: null,
      imgWidth: null,
      imageId: "",
      isLocalVideoMuted: false,
      uploadInProgress: false,
      ringtone: null,
      suspicious: {
        options: [{ text: 'True', value: true }, { text: 'False', value: false }],
        selected: false
      },
      docMatch: {
        options: [{ text: 'True', value: true }, { text: 'False', value: false }],
        selected: false
      },
      modalIncomigCall: {
        modalActive: false,
        variant: '',
        title: '',
        message: '',
        accept: null,
        acceptLabel: '',
        cancel: null,
        cancelLabel: ''
      }
    }
  },
  async mounted() {
    
    Janus.init({
      callback: this.onJanusInitialized,
      debug: true,
    });

    this.isHost = this.hostProp ? true : false;

    const currentUserUsername = this.isHost
      ? this.agentProp ?? ""
      : getQueryParamValue("phone") ?? ""
    const recipientUserUsername = this.isHost
      ? this.userProp ?? ""
      : getQueryParamValue("agent") ?? ""


    this.users = [currentUserUsername,recipientUserUsername]
    this.ringtone = new Audio(require('../../../assets/incoming_call.mp3')); 
    this.ringtone.loop = true;
  },
  computed: {
    ...mapGetters({
      getDocumentNumber: "user/getDocumentNumber"
    }),
    anotherFacingMode() {
      return this.cameraFacingMode === 'user'
        ? 'environment'
        : 'user';
    },
    selectedLength() {
      let countProfile = this.screenshots.filter(({ profile }) => profile).length;
      let countFace = this.screenshots.filter(({ face }) => face).length;
      let countObverse = this.screenshots.filter(({ obverse }) => obverse).length;
      let countReverse = this.screenshots.filter(({ reverse }) => reverse).length;
      return countProfile + countFace + countObverse + countReverse
    },
    selectedProfile(){
      return this.screenshots.filter(screenshot => screenshot.profile).length == 1 ? true : false;
    },
    selectedFace(){
      return this.screenshots.filter(screenshot => screenshot.face).length == 1 ? true : false;
    },
    selectedObverse(){
      return this.screenshots.filter(screenshot => screenshot.obverse).length == 1 ? true : false;
    },
    selectedReverse(){
      return this.screenshots.filter(screenshot => screenshot.reverse).length == 1 ? true : false;
    }
  },
  methods: {
    ...mapActions({
      SendFiles: "user/SendFiles",
      SendProfileImage: "user/SendProfileImage",
      getProfileImage: "user/getProfileImage"
    }),
    modalHangup(){
      let text = 'En estos momentos nuestros agentes se encuentra ocupados, en breve uno de ellos se comunicara con usted'
      let url = href(text,'57'+this.fromUsername);
      window.open(url, '_blank');
      this.modalReset();
      this.hangup();
    },
    modalAnswer(jsep){
      this.modalReset();
      this.plugin.createAnswer({
          jsep,
          media: { data: true },
          success: token => this.plugin.send({ message: { request: "accept" }, jsep: token }),
          error: error => this.onError(error),
        });
    },
    modalReset(){
      this.ringtone.pause();
      this.modalIncomigCall = {
        modalActive: false,
        variant: '',
        title: '',
        message: '',
        accept: null,
        acceptLabel: '',
        cancel: null,
        cancelLabel: ''
      }
    },
    setImageId(clientId){
      console.log("Entre")
      this.imageId = clientId
    },
    onJanusInitialized() {
      if (!Janus.isWebrtcSupported()) {
        this.onError('Could not initialize Janus', true);
        return;
      }

      const instance = new Janus({
        server,
        iceServers :[{url: 'stun:stun.l.google.com:19302',
        credential: '',
        username: ''}],
        success: () => this.onJanusSuccess(instance),
        error: (e) => this.onError(e),
        destroyed: () => console.log("destroyed"),
      });
    },
    onJanusSuccess(instance) {
      instance.attach({
        plugin: 'janus.plugin.videocall',
        opaqueId: `videocalltest-${Janus.randomString(12)}`,
        success: this.onPluginSuccess,
        onmessage: this.onMessage,
        onlocalstream: this.onLocalStream,
        onremotestream: this.onRemoteStream,
        ondata: this.onData,
      });
    },
    onPluginSuccess(plugin) {
      this.plugin = plugin;
      if (this.users[0]) {
        this.registerUser(this.users[0]);
      }
      this.canShowDialForm = true;
    },
    onMessage(msg, jsep) {
      if (msg.result) {
        console.warn("onMessage: "+msg.result.event);
        switch (msg.result.event) {
          case 'registered':
            this.onUserRegistered(msg.result.username);
            break;
          case 'incomingcall':
            if (!this.callInProgress) {
              this.onIncomingCall(msg.result.username, jsep);
            }
            break;
          case 'hangup':
            this.modalReset();
            this.callRequested = false;
            this.callInProgress = false;
            this.isLocalVideoMuted = false; 
            break;
          case 'accepted':
            this.callInProgress = true;
            this.hadCall = true;
            if (jsep) this.plugin.handleRemoteJsep({ jsep });
            this.setUpRecording();
            break;
          case 'update':
            if (!jsep) break;

            if (jsep.type === 'answer') {
              this.plugin.handleRemoteJsep({ jsep })
            } else {
              this.plugin.createAnswer({
                jsep,
                media: { data: true },
                success: token => {
                  this.plugin.send({ message: { request: "set"}, jsep: token })
                },
                error: e => this.onError(e),
              });
            }
            break;
          default:
            break;
        }
      }
      if (msg.error) {
        let text = msg.error_code === 478 ? `El agente ${(this.users[1]).toUpperCase()} no se encuentra disponible en este momento` : msg.error
        this.modalIncomigCall = {
          modalActive: true,
          variant: "is-danger",
          title: "ALERTA",
          message: text,
          cancel: () => {
            this.hangup();
            this.modalReset();
          },
          cancelLabel: "Aceptar",
        }
        //alert(msg.error)
      }
    },
    onLocalStream(stream) {
      const video = this.$refs.localVideoContainer.$el.getElementsByTagName('video')[0];
      Janus.attachMediaStream(video, stream);
      video.muted = 'muted';
      if (this.plugin.webrtcStuff.pc.iceConnectionState !== 'completed' &&
        this.plugin.webrtcStuff.pc.iceConnectionState !== 'connected') {
          this.isPublishing = true;
          this.waitingForRemoteVideo = true;
      }
      const videoTracks = stream.getVideoTracks();
      this.noLocalVideo = !videoTracks || videoTracks.length === 0;
    },
    onRemoteStream(stream) {
      console.error("**onRemoteStream**")
      const video = this.$refs.remoteVideoContainer.$el.getElementsByTagName('video')[0];
      console.warn(video,"videooo");
      Janus.attachMediaStream(video, stream);
      const videoTracks = stream.getVideoTracks();
      this.noRemoteVideo = !videoTracks || videoTracks.length === 0;
      this.isPublishing = false;
      this.waitingForRemoteVideo = false;
    },
    onError(e, critical = false) {
      console.error("onError JANUS", e);
      //this.$bvModal.msgBoxOk(typeof e === 'string' ? e : ((e && e.message) || 'Something went wrong'));
      //alert(typeof e === "string" ? e : ((e && e.message) || "Something went wrong"));
      this.hangup();
      this.dispose();
      if (critical) {
        this.hasError = true;
      }
    },
    changeUser(data){
        if(data.user==="user1") this.users[0] = data.value
        if(data.user==="user2") this.users[1] = data.value
    },
    setUpRecording() {
      let basename = `/recording/${this.users[1]}`;
      let filename = `${(new Date()).toISOString()}-${this.users[1]}-${this.users[0]}`;
      let dirname = `${basename}/${filename}`;
      this.videoId = filename;
      this.plugin.send({ message: { request: "set", record: true, filename: dirname }});
    },
    answerCall(){
      this.plugin.createAnswer({
        jsep,
        media: { data: true },
        success: token => this.plugin.send({ message: { request: "accept" }, jsep: token }),
        error: error => this.onError(error),
      });
    },
    declineCall(){
      this.hangup();
    },
    registerUser(username) {
      const message = { request: "register", username };
      this.plugin.send({ message });
    },
    onCall(username) {
      this.plugin.createOffer({
        media: { data: true, audio: true, video: { facingMode: this.cameraFacingMode }},
        iceRestart: true,
        success: jsep => {
          this.plugin.send({ message: { request: 'call', username }, jsep });
          this.callRequested = true;
        },
        error: e => this.onError(e),
      });
    },
    onUserRegistered(username) {
      this.userRegistered = true;
      this.registeredUsername = username;
    },
    onIncomingCall(fromUsername, jsep) {
      this.callRequested = true;
      this.fromUsername = fromUsername;
      this.playRingtone();
      
      if(fromUsername!==this.users[1]) {

        this.modalIncomigCall = {
          modalActive: true,
          variant: "is-danger",
          title: "ALERTA",
          message: "Está llamando un cliente que no es el que tienes en pantalla. Por favor cambia de cliente y llamalo nuevamente para poder gestionarlo",
          cancel: () => this.modalHangup,
          cancelLabel: "Colgar y notificar via WhatsApp",
        }
      }else{

        this.modalIncomigCall = {
          modalActive: true,
          variant: "is-success",
          title: "ALERTA",
          message: `Está llamando ${fromUsername}, desea responder? `,
          cancel: this.modalHangup,
          cancelLabel: "Colgar",
          accept: () => this.modalAnswer(jsep),
          acceptLabel: "Contestar",
        }
      }

      
      // this.$bvModal.msgBoxConfirm(`Incoming call from ${fromUsername}`, {
      //   title: 'Incoming call',
      //   size: 'sm',
      //   buttonSize: 'sm',
      //   okVariant: 'success',
      //   okTitle: 'Answer',
      //   cancelTitle: 'Decline',
      //   cancelVariant: 'danger',
      //   footerClass: 'p-2',
      //   hideHeaderClose: true,
      //   centered: true,
      // }).then(willAnswer => {
      //   this.ringtone.pause();
      //   if (willAnswer) {
      //     this.plugin.createAnswer({
      //       jsep,
      //       media: { data: true },
      //       success: token => this.plugin.send({ message: { request: "accept" }, jsep: token }),
      //       error: error => this.onError(error),
      //     });
      //   } else {
      //     this.hangup();
      //   }
      // })
    },
    onData(data) {
      if (data === 'ROTATE') {
        this.plugin.createOffer({
          media: {
            replaceVideo: true,
            replaceAudio: true,
            video: {
              facingMode: this.anotherFacingMode,
            }
          },
          success: jsep => {
            this.plugin.send({ message: { request: 'set' }, jsep });
            this.cameraFacingMode = this.anotherFacingMode;
            this.$refs.remoteVideoContainer.checkRatio();
          },
          error: this.onError,
        });
      }
    },
    dispose() {
      this.callRequested = false;
      this.callInProgress = false;
      this.isPublishing = false;
      this.noLocalVideo = true;
      this.waitingForRemoteVideo = false;
      this.noRemoteVideo = true;
    },
    hangup() {
      this.plugin.send({ message: { request: 'hangup' } });
      this.isLocalVideoMuted = false;
    },
    onScreenShot({ video, fileName, height, width }) {
      if (!this.imgHeight) {
        this.imgHeight = height
      }
      if (!this.imgWidth) {
        this.imgWidth = width;
      }
      this.makeCanvas(video, fileName);
    },
    makeCanvas(video, fileName) {
      const canvas = document.createElement('canvas');
      if (video) {
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        canvas.getContext('2d').drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
        canvas.toBlob((blob) => {
          const url = URL.createObjectURL(blob)
          this.screenshots.unshift({ fileName, url, obverse: false, reverse: false, profile: false, face: false , blob });
        });
      }
    },
    downloadImage(url, filename) {
      console.log({url, filename})
      const a = document.createElement('a');
      a.href = url;
      a.download = filename || 'download';
      const clickHandler = () => {
        setTimeout(() => {
          a.removeEventListener('click', clickHandler);
        }, 150);
      };
      a.addEventListener('click', clickHandler, false);
      a.click();
    },
    async onUpload() {

      try {
        this.uploadInProgress = true;
      await Promise.all(this.screenshots
        .map(async (item) => {          

          let base64 = await blobToBase64(item.blob);

          if (this.imageId) {
            if(item.face || item.obverse || item.profile || item.reverse ){

              let side;              
              if (item.face) side = 'Cara' 
              if (item.profile) side = 'Perfil'
              if (item.reverse) side = 'Reverso'
              if (item.obverse) side = 'Anverso'

              let isSaved = await imageToBucket(base64, this.imageId, side)
              console.log({isSaved})

              if (isSaved) {
                this.$notyf.success(`Imagen guardada`)
              } else {
                throw (`Error al cargar la imagen`)
              }
            }
          } else {
           throw `Debe ingresar la cedula del cliente para poder enviar las imágenes`
          }

          // try {
          //   let response = null
          //   if(item.profile){
          //     response = await this.SendProfileImage({
          //       image: {
          //         fileContent: base64,
          //         fileFormat: "png",
          //         fileName: item.fileName,
          //         mediaType: item.blob.type
          //       },
          //       documentNumber: this.getDocumentNumber
          //     })
          //     await this.getProfileImage(this.getDocumentNumber)

          //   }else{

          //     let description = item.profile ? "Foto perfil" : item.face ? "Foto cara" : item.obverse ? "Foto anverso" : "Foto reverso"

          //     response = await this.SendFiles({ 
          //       file: {
          //         fileContent: base64,
          //         fileFormat: "png",
          //         fileName: item.fileName,
          //         mediaType: item.blob.type,
          //         fileDescription: description
          //       },
          //       documentNumber: this.getDocumentNumber
          //     });

          //   }
          //   let msj = response.status===202 ? "imagen enviada correctamente a Mifos. NOTA: para esta imagen no ha sido posible su carga a Amazon s3 en el momento" : "Imagen enviada"
          //   this.$notyf.success(msj)

          // } catch (error) {
          //   this.$notyf.error(error)
          // }
                 
        }));
        this.screenshots = []
        this.uploadInProgress = false;
      } catch (error) {
        this.$notyf.error(error)
        this.uploadInProgress = false;
      }

    },
    onFlipCamera() {
      this.plugin.data({ text: 'ROTATE' });
    },
    onMuteToggle(resetState) { 
      if (resetState) {
        this.isLocalVideoMuted = false;
      }else {
        this.isLocalVideoMuted = !this.isLocalVideoMuted
        this.plugin.send({ message: { request: "set", audio: !this.isLocalVideoMuted }})
      }
    },
    playRingtone() {
      this.ringtone.play().catch(e => {
        console.log("Cant play ringtone with following error:")
        console.error(e)
      })
    },
    sendImages(images) {
      console.log(imageToBucket(images))
    }
  },
  beforeDestroy(){
    this.plugin.session.destroy();
  }
}
</script>
<style scoped>
.content ul {
  list-style: none;
}
.content li + li {
  margin-top: 0px;
}
.container-janus{
  padding-top: 20px; 
}
</style>