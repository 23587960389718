<template>
  <div>
    <article class="message is-warning">
      <div class="message-header">
        INFORMACIÓN
      </div>
      <div class="message-body">
        El credito de este cliente se encuentra en estado "Desistido", ¿esta
        seguro que lo desea retomar?
      </div>
      <div class="has-text-right p-2">
        <button class="button is-success is-outlined" @click="undoDesist">
          Retomar
        </button>
      </div>
    </article>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex"

export default {
  data() {
    return {
      loading: false
    }
  },
  methods: {
    async undoDesist() {
      try {
        this.loading = true
        let londActive = this.getloanActive
        await this.postUndoLoanDesist(londActive.id)
        this.loading = false
        this.cleanData()
        this.$notyf.success("Crédito retomado")
      } catch (err) {
        this.$notyf.error(err.message)
      }
    },
    ...mapActions({
      cleanData: "user/cleanData",
      postUndoLoanDesist: "user/postUndoLoanDesist"
    })
  },
  computed: {
    ...mapGetters({
      getloanActive: "user/getloanActive"
    })
  }
}
</script>
