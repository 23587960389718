<script>
import { firstDatePayment, getSimulator } from "@/services/validation"
import monthlyQuote from "@/helpers/monthlyQuote.js"
import totalDisbursed from "@/helpers/totalDisbursed.js"
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      simulator: {
        VAT: 0,
        guaranteeConstant: 0,
        guaranteeFurniture: 0,
        guaranteeVariableMan: 0,
        guaranteeVariableWoman: 0,
        interest: 0,
        electronicSignature: 0
      },
      params: {
        income: 0,
        riskMatrix: undefined
      },
      values: {
        minAmount: 0,
        maxAmount: {},
        amount: 0,
        terms: [0],
        term: 0,
        gender: ""
      },
      computed: {
        firstDatePayment: "",
        monthlyQuote: 0,
        totalDisbursed: 0
      },
      valueFirstDatePayment: "",
      formarFirstDatePayment: "",
      ready: false
    }
  },
  methods: {
    setValues(value) {
      console.log('setVales ==> ', value)
      this.values = value
    },
    setParams(value) {
      this.params = value
    },
    setComputed(obj) {
      this.computed = obj
    },
    removeItemArray(arr, item){
      let i = arr.indexOf(item);
      if (i !== -1) {
        arr.splice(i, 1);
      }
      return arr;
    },
    async setfirstDatePayment() {
      let day = await firstDatePayment()
      this.formarFirstDatePayment = day.format("DD-MM-YYYY")
      this.valueFirstDatePayment = day.format("MMMM DD [de] YYYY")
    },
    async setSimulator() {
      this.simulator = await getSimulator(this.getDocumentNumber)
    },
    maxAmountGen(
      interest,
      electronicSignature,
      disposableIncome,
      guaranteeVariable,
      amountMax,
      incomeFactor
    ) {

      return term => {
        const interestMonthly = Math.pow(1 + interest, 1 / 12) - 1;
        const valueElectronicSignature = electronicSignature / term;
        const paymentFactor = 1 - Math.pow(1 + interestMonthly, -term);
        const income = disposableIncome * incomeFactor;

        const max =
          Number(
            Number(
              ((income - valueElectronicSignature) *
                (paymentFactor /
                  (interestMonthly + guaranteeVariable * 30 * paymentFactor))) /
                500000
            ).toFixed(1)
          ) * 500000;

        return Math.min(max, amountMax);
      }
    },
    fiftyMultiplier(value) {
      return value - (value % 50000)
    }
  },
  computed: {
    ...mapGetters({
       getDocumentNumber: "user/getDocumentNumber",
    })
  },
  mounted() {
    this.setfirstDatePayment()
    this.setSimulator()
  },
  watch: {
    params: {
      deep: true,
      handler() {
        if (!this.params.riskMatrix) return
        let { riskMatrix, incomeFactor, disposableIncome } = this.params

        let {
          interest,
          electronicSignature,
          guaranteeVariableWoman,
          guaranteeVariableMan
        } = this.simulator

        let max = this.maxAmountGen(
          interest,
          electronicSignature,
          disposableIncome,
          this.values.gender === "Masculino"
            ? guaranteeVariableMan
            : guaranteeVariableWoman,
          riskMatrix.maxAmount,
          incomeFactor
        )

        const maxAmount = riskMatrix.term.reduce(
          (o, term) => ({ ...o, [term]: max(term) }),
          {}
        )

        const minAmount = this.fiftyMultiplier(riskMatrix.minAmount)
        const term = riskMatrix.term[riskMatrix.term.length - 1]
        console.log('riskMatrix ==> ', riskMatrix.term.length - 1)

        for (let k in maxAmount) {
          if (
            [12, 18, 24].indexOf(parseInt(k)) !== -1 &&
            maxAmount[k] < riskMatrix.minAmount
          ) {
            delete maxAmount[k];
            riskMatrix.term = this.removeItemArray(riskMatrix.term, parseInt(k));
          }
        }

        this.setValues({
          gender: this.values.gender,
          amount: maxAmount[term],
          maxAmount,
          minAmount,
          term,
          terms: riskMatrix.term
        })
        this.ready = true
      }
    },
    values: {
      deep: true,
      handler() {
        const { amount, term, gender } = this.values

        const {
          interest,
          electronicSignature,
          guaranteeConstant,
          guaranteeFurniture,
          guaranteeVariableMan,
          guaranteeVariableWoman,
          VAT
        } = this.simulator

        this.setComputed({
          monthlyQuote: monthlyQuote({
            amount,
            term,
            interest,
            electronicSignature,
            guaranteeVariable:
              gender === "Masculino"
                ? guaranteeVariableMan
                : guaranteeVariableWoman,
            VAT
          }),
          totalDisbursed: totalDisbursed({
            amount,
            guaranteeConstant,
            guaranteeFurniture
          }),
          firstDatePayment: this.valueFirstDatePayment
        })
      }
    },
    simulator: {
      deep: true,
      handler() {
        const { amount, term, gender } = this.values
        const {
          interest,
          electronicSignature,
          guaranteeConstant,
          guaranteeFurniture,
          guaranteeVariableMan,
          guaranteeVariableWoman,
          VAT
        } = this.simulator

        this.setComputed({
          monthlyQuote: monthlyQuote({
            amount,
            term,
            interest,
            electronicSignature,
            guaranteeVariable:
              gender === "Masculino"
                ? guaranteeVariableMan
                : guaranteeVariableWoman,
            VAT
          }),
          totalDisbursed: totalDisbursed({
            amount,
            guaranteeConstant,
            guaranteeFurniture
          }),
          firstDatePayment: this.valueFirstDatePayment
        })
      }
    }
  }
}
</script>
