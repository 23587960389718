<template>
  <div class="container">
    <div class="label">{{ label }}:</div>
    <div>{{ value_format(value) }}</div>
  </div>
</template>
<script>
import moneyFormat from "@/helpers/moneyFormat"
export default {
  props: {
    label: {
      type: String,
      required: true
    },
    value: {
      type: [Number, String],
      required: true
    },
    format: Boolean
  },
  methods: {
    value_format(value_input) {
      if (this.format) {
        return moneyFormat(value_input)
      } else {
        return value_input
      }
    }
  }
}
</script>
<style scoped>
.container {
  margin-bottom: 10px;
}
.label {
  font-weight: 700;
}
</style>
