<template>
  <div>
    <form @submit.prevent="validateBasicInfo" autocomplete="off">
      <div class="columns">
        <div class="column">
          <div class="field">
            <label class="label required">Cédula de ciudadanía</label>
            <div class="control">
              <input
                class="input"
                type="text"
                v-model.trim="$v.documentNumber.$model"
                :class="{ 'is-danger': $v.documentNumber.$error }"
                readonly
                disabled
              />
            </div>
          </div>
        </div>
        <div class="column">
          <div class="field">
            <label class="label required">Nombres</label>
            <div class="control">
              <input
                class="input"
                type="text"
                v-model.trim="$v.givenname.$model"
                :class="{ 'is-danger': $v.givenname.$error }"
                v-focus
              />
            </div>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <div class="field">
            <label class="label required">Primer apellido</label>
            <div class="control">
              <input
                class="input"
                type="text"
                v-model.trim="$v.surname.$model"
                :class="{ 'is-danger': $v.surname.$error }"
              />
            </div>
          </div>
        </div>
        <div class="column">
          <div class="field">
            <label class="label required">Segundo apellido</label>
            <div class="control">
              <input
                class="input"
                type="text"
                v-model.trim="$v.lastname.$model"
                :class="{ 'is-danger': $v.lastname.$error }"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-4 is-offset-4">
          <div class="buttons">
            <button
              class="button is-primary is-medium is-fullwidth"
              :disabled="isLoading || $v.$anyError"
            >
              Validar Procuraduría
            </button>
            <button
              class="button is-danger is-medium is-fullwidth"
              v-show="isValidName"
              :disabled="isLoading"
              @click.prevent="getUbica()"
            >
              Consultar Ubica
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex"
import { required, minLength } from "vuelidate/lib/validators"
import { validateNames } from "@/services/validation"
import { validatorNames } from "../../helpers/validators"
import validatePermissions from "../../helpers/validatePermissions"

export default {
  data() {
    return {
      documentNumber: "",
      givenname: "",
      surname: "",
      lastname: "",
      isValidName: false,
      isLoading: false
    }
  },
  validations: {
    documentNumber: {
      required,
      minLength: minLength(5)
    },
    givenname: {
      required,
      validatorNames
    },
    surname: {
      required,
      validatorNames
    },
    lastname: {
      required,
      validatorNames
    }
  },
  beforeMount(){
    validatePermissions("INFO_BAS","No tiene permisos para gestionar a un cliente en la pantalla de información Basíca");
  },
  mounted() {
    this.getDocument()
  },
  methods: {
    getDocument() {
      this.documentNumber = this.getDocumentNumber
    },
    async validateBasicInfo() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.isLoading = true
        try {
          let response = await validateNames(
            this.documentNumber,
            this.givenname,
            this.surname,
            this.lastname
          )
          if (response.fieldsAreCorrect) {
            let basicInfo = this.getBasicInfo
            basicInfo.givenname = this.givenname
            basicInfo.surname = this.surname
            basicInfo.lastname = this.lastname
            this.setBasicInfo(basicInfo)
            this.isValidName = true
          } else {
            this.$notyf.error("La información ingresada no es válida.")
          }
        } catch (error) {
          this.$notyf.error(error)
        }
        this.isLoading = false
      }
    },
    async getUbica() {
      this.isLoading = true
      try {
        await this.ubicaUser({
          documentNumber: this.documentNumber,
          surname: this.surname
        })
        this.completeFormStep("basicInformation")
      } catch (error) {
        this.$notyf.error(error)
      }
      this.isLoading = false
    },
    ...mapActions({
      ubicaUser: "user/getUbica"
    }),
    ...mapMutations({
      completeFormStep: "user/completeFormStep",
      setBasicInfo: "user/setInfo"
    })
  },
  computed: {
    ...mapGetters({
      getDocumentNumber: "user/getDocumentNumber",
      getBasicInfo: "user/getBasicInfo"
    })
  }
}
</script>

<style></style>
