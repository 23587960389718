<template>
  <div class="p-2">
    <div class="row">
      <div class="col-md-3 p-2">
        <img
          v-if="getPhotoProfile"
          :src="getPhotoProfile"
          class="img-fluid img-thumbnail rounded"
          alt="Responsive image"
        />
        <img
          v-else
          src="@/assets/avatar.jpg"
          class="img-fluid img-thumbnail rounded"
          alt="Responsive image"
        />
      </div>
      <div class="col-md-3 col-lg-3">
        <div class="form-group">
          <input
            id="clientId"
            type="number"
            class="form-control mb-2"
            placeholder="Ingrese la cédula"
            autocomplete="nope"
            v-model="clientId"
            @change="getImageId"
          />
          <input
            id="clientFirstName"
            type="text"
            class="form-control mb-2"
            placeholder="Nombres"
            autocomplete="nope"
            v-model="clientFirstName"
          />
          <input
            id="clientLastName"
            type="text"
            class="form-control mb-2"
            placeholder="Apellidos"
            autocomplete="nope"
            v-model="clientLastName"
          />
          <input
            id="clientPhone"
            type="number"
            class="form-control mb-2"
            placeholder="Teléfono celular"
            autocomplete="nope"
            v-model="clientPhone"
          />

          <div class="text-center mt-5">
            <label>¿Que desea hacer?</label>
            <div>
              <button
                class="btn btn-outline-primary btn-sm m-1"
                @click="approve"
              >
                Aprobar
              </button>
              <button class="btn btn-outline-danger btn-sm m-1" @click="reject">
                Rechazar
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3 col-lg-3">
        <div class="row">
          <div class="col-12 pb-2">
            <div class="form-check form-switch">
              <input
                class="form-check-input"
                type="checkbox"
                id="faceDocumentMatch"
                v-model="faceDocumentMatch"
              />
              <label class="form-check-label" for="faceDocumentMatch"
                >Coincidencia cara a documento</label
              >
            </div>
          </div>
          <div class="col-12 pb-2">
            <div class="form-check form-switch">
              <input
                class="form-check-input"
                type="checkbox"
                id="suspiciousBehaviour"
                v-model="suspiciousBehaviour"
              />
              <label class="form-check-label" for="suspiciousBehaviour"
                >Comportamientos sospechosos</label
              >
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3 col-lg-3">
        <div class="form-group">
          <textarea
            maxlength="128"
            class="form-control"
            id="observacion"
            v-model="observation"
            rows="4"
            placeholder="Observaciones"
          ></textarea>
          <small class="form-text text-muted"
            >{{ restCharacters }} caracteres</small
          >
        </div>
      </div>
    </div>
    <hr class="my-2" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import {crmSendInfo} from "@/services/telephant"

export default {
  props: {
    videoId: {
      type: String,
      default: null
    },
    test: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      observation: "",
      clientId: "",
      clientFirstName: "",
      clientLastName: "",
      clientPhone: "",
      faceDocumentMatch: false,
      suspiciousBehaviour: false,
      loading: false
    }
  },
  computed: {
    ...mapGetters({
      getPhotoProfile: "user/getPhotoProfile",
      getBuroResult: "user/getBuroResult",
      getDocumentNumber: "user/getDocumentNumber",
      getloanActive: "user/getloanActive"
    }),
    restCharacters() {
      return 128 - this.observation.length
    },
    getClientId(){
      return this.clientId
    }
  },
  methods: {
    ...mapActions({
      sendElectronicId: "user/sendElectronicId",
      getClient: "user/getClient"
    }),
    async reject() {
      try {
        this.loading = true
        if (!this.videoId) throw "VideoId indefinido"
        if (
          !this.clientPhone ||
          !this.clientId ||
          !this.clientFirstName ||
          !this.clientLastName
        )
          throw "Por favor diligencie todos los campos del formulario"

        // let decision = this.getBuroResult.filter(
        //   row => row.label == "Rechazado"
        // )
        // let payload = {
        //   videoId: this.videoId,
        //   status: "Failed",
        //   decisionFinal: decision[0].value,
        //   observations: this.observation,
        //   faceDocumentMatch: this.faceDocumentMatch,
        //   suspiciousBehaviour: this.suspiciousBehaviour
        // }
        // await this.sendElectronicId({
        //   loanId: this.getloanActive.id,
        //   documentNumber: this.getDocumentNumber,
        //   payload: payload
        // })

        const name = `${this.clientFirstName} ${this.clientLastName}`
        const state = "Rechazado"

        let crmResponse = await crmSendInfo(this.clientId, name, this.clientPhone, state)
        

        if(!crmResponse) throw "Error en CRM"
        this.$notyf.success("Rechazado satisfactoriamente")

        this.loading = false
        // this.$emit("hangup")
        // await this.getClient({
        //   value: this.getDocumentNumber,
        //   method: "id"
        // })
      } catch (error) {
        this.loading = false
        
        this.$notyf.error(error)
      }
    },
    async getImageId(){
      let id = await this.clientId
      this.$emit('clientImageId', id)
    },
    async approve() {
      try {
      this.loading = true
      if (!this.videoId) throw "VideoId indefinido"

        if (
          !this.clientPhone ||
          !this.clientId ||
          !this.clientFirstName ||
          !this.clientLastName
        )
          throw "Por favor diligencie todos los campos del formulario"

        // let decision = this.getBuroResult.filter(row => row.label == "Aprobado")
        // let payload = {
        //   videoId: this.videoId,
        //   status: "Completed",
        //   decisionFinal: decision[0].value,
        //   observations: this.observation,
        //   faceDocumentMatch: this.faceDocumentMatch,
        //   suspiciousBehaviour: this.suspiciousBehaviour
        // }
        // await this.sendElectronicId({
        //   loanId: this.getloanActive.id,
        //   documentNumber: this.getDocumentNumber,
        //   payload: payload
        // })
        // this.loading = false

        const name = `${this.clientFirstName} ${this.clientLastName}`
        const state = "Aprobado"

        let crmResponse = await crmSendInfo(this.clientId, name, this.clientPhone, state)
         console.log(crmResponse)

        if(!crmResponse) throw "Error en CRM"
        this.$notyf.success("Aprobado satisfactoriamente")

        this.loading = false
        // this.$emit("hangup")
        // await this.getClient({
        //   value: this.getDocumentNumber,
        //   method: "id"
        // })
      } catch (error) {
        console.log(error)
        this.loading = false
        this.$notyf.error(error)
      }
    }
  }
}

</script>

<style></style>
