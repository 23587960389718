<template>
  <div>
    <form @submit.prevent="submitEmailPhone" autocomplete="off">
      <div class="columns">
        <div class="column is-half is-offset-one-quarter">
          <div class="field">
            <label class="label">Número de celular</label>
            <div class="control">
              <input
                class="input"
                type="text"
                v-model.trim="$v.phone.$model"
                :class="{ 'is-danger': $v.phone.$error }"
                @focus="$v.phoneSelected.$model = ''"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-half is-offset-one-quarter">
          <div class="field">
            <label class="label"
              >Números de celular recuperados del cliente</label
            >
            <div class="control">
              <div v-for="phone in getPhones" :key="phone.id">
                <label class="radio">
                  <input
                    type="radio"
                    v-model="$v.phoneSelected.$model"
                    :value="phone.id"
                    @click="$v.phone.$model = ''"
                  />
                  <span> {{ phone.phone }}</span>
                </label>
                <br />
              </div>
              <div v-if="getPhones.length == 0">
                No tiene celulares registrados.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-half is-offset-one-quarter">
          <div class="field">
            <label class="label">Correo</label>
            <div class="control">
              <input
                class="input"
                type="text"
                v-model.trim="$v.email.$model"
                :class="{ 'is-danger': $v.email.$error }"
                @focus="$v.emailSelected.$model = ''"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-half is-offset-one-quarter">
          <div class="field">
            <label class="label"
              >Correo electrónico recuperados del cliente</label
            >
            <div class="control">
              <div v-for="email in getEmails" :key="email.id">
                <label class="radio">
                  <input
                    type="radio"
                    v-model="$v.emailSelected.$model"
                    :value="email.id"
                    @click="$v.email.$model = ''"
                  />
                  <span> {{ email.email }}</span>
                </label>
                <br />
              </div>
              <div v-if="getEmails.length == 0">
                No tiene correos registrados.
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="columns">
        <div class="column is-4 is-offset-4">
          <div class="buttons">
            <button
              class="button is-primary is-medium is-fullwidth"
              :disabled="isLoading || $v.$anyError"
            >
              Validar email y celular
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { numeric, email, requiredIf } from "vuelidate/lib/validators"
import { validateEmailPhone } from "@/services/validation"
import validatePermissions from "../../helpers/validatePermissions"
import { mapMutations, mapGetters, mapActions } from "vuex"
export default {
  data() {
    return {
      phone: "",
      phoneSelected: "",
      email: "",
      emailSelected: "",
      isLoading: false
    }
  },
  validations: {
    phone: {
      required: requiredIf(function() {
        return this.phoneSelected == ""
      }),
      numeric
    },
    phoneSelected: {
      required: requiredIf(function() {
        return this.phone == ""
      })
    },
    email: {
      required: requiredIf(function() {
        return this.emailSelected == ""
      }),
      email
    },
    emailSelected: {
      required: requiredIf(function() {
        return this.email == ""
      })
    }
  },
  beforeMount(){
    validatePermissions("UBICA","No tiene permisos para gestionar a un cliente en la pantalla de Ubica");
  },
  methods: {
    async submitEmailPhone() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.isLoading = true
        try {
          let phone = this.getPhones.find(
            phone => this.phoneSelected == phone.id
          )
          let email = this.getEmails.find(
            email => this.emailSelected == email.id
          )

          let phoneUser =
            this.phone !== "" ? { phone: this.phone, id: -1 } : phone
          let emailUser =
            this.email !== "" ? { email: this.email, id: -1 } : email

          let response = await validateEmailPhone(
            this.getDocumentNumber,
            emailUser.email,
            phoneUser.phone
          )
          if (response.fieldsAreCorrect) {
            this.setUbicaSelected({
              phone: phoneUser,
              email: emailUser
            })
            this.completeFormStep("ubica")
          } else {
            this.$notyf.error(
              "Existe un usuario en JTP con la misma información."
            )
          }
        } catch (error) {
          this.$notyf.error(error)
        }
        this.isLoading = false
      }
    },
    ...mapMutations({
      completeFormStep: "user/completeFormStep",
      setUbicaSelected: "user/setUbicaSelected"
    }),
    ...mapActions({
      getClient: "user/getClient"
    })
  },
  computed: {
    ...mapGetters({
      getDocumentNumber: "user/getDocumentNumber",
      getPhones: "user/getUbicaPhones",
      getEmails: "user/getUbicaEmails",
      getAddresses: "user/getUbicaAddresses"
    })
  }
}
</script>

<style></style>
