<template>
  <div>
    <div v-if="error != ''">
      <div class="notification is-danger is-light">
        {{ error }}
      </div>
    </div>
    <div v-else>
      <form @submit.prevent="sendMethodId" autocomplete="off">
        <div class="columns">
          <div class="column is-6 is-offset-3 is-size-4 has-text-centered">
            Confirma cómo quieres hacer uso de tu crédito
          </div>
        </div>
        <div class="columns is-multiline">
          <div class="column is-6 is-offset-3">
            <li
              v-for="accountType in eventTypeEnable"
              :key="accountType.idx"
              class="mt-2"
              :style="{ opacity: accountType.disabled ? 0.2 : 1 }"
            >
              <label>
                <input
                  type="radio"
                  :value="accountType.value"
                  :id="accountType.idx"
                  name="disbursementMethodId"
                  v-model="$v.disbursementMethodId.$model"
                  :disabled="accountType.disabled"
                />
                {{ accountType.label }}
              </label>
            </li>
          </div>
        </div>
        <div class="columns">
          <div class="column is-4 is-offset-4">
            <div class="buttons">
              <button
                class="button is-primary is-medium is-fullwidth"
                :disabled="isLoading || $v.$anyError"
              >
                Continuar
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import { methodIdPost, videoIDGet } from "@/services/user"
import validatePermissions from "../../helpers/validatePermissions"
import { mapGetters, mapMutations } from "vuex"
import { required } from "vuelidate/lib/validators"

export default {
  data() {
    return {
      isLoading: false,
      error: "",
      disbursementMethodId: "",
      videoId: ""
    }
  },
  validations: {
    disbursementMethodId: { required }
  },
  async beforeMount() {

    validatePermissions("MET_DES","No tiene permisos para gestionar a un cliente en la pantalla de Método de desembolso");

    try {
      this.videoId = await videoIDGet(this.getDocumentNumber, this.getLoan.id)
    } catch (err) {
      this.error = err
    }
  },
  methods: {
    ...mapMutations({
      completeFormStep: "user/completeFormStep"
    }),
    async sendMethodId() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        try {
          this.isLoading = true
          await methodIdPost(this.getLoan.id, {
            disbursementMethodId: this.disbursementMethodId
          })
          this.isLoading = false
          this.completeFormStep("disbursementMethod")
        } catch (error) {
          this.$notyf.error(error)
        }
      } else {
        this.$notyf.error("Seleccione una opción")
      }
    }
  },
  computed: {
    ...mapGetters({
      getEventType: "user/getEventType",
      getDocumentNumber: "user/getDocumentNumber",
      getLoan: "user/getApprovedOrApprovedAwaitingSignature"
    }),
    eventTypeEnable() {
      return this.getEventType
        .map(obj => {
          let disabled = !this.videoId.successfully ? true : false
          if (obj.value === 1406) {
            return {
              ...obj,
              idx: 1,
              label: "Desembolso a mi cuenta bancaria",
              disabled
            }
          } else if (obj.value === 1405) {
            return {
              ...obj,
              idx: 3,
              label: "Compra en comercios y tiendas aliadas",
              description:
                "(El crédito se desembolsa directamente al comercio)",
              disabled
            }
          } else if (obj.value === 1401) {
            return {
              ...obj,
              idx: 2,
              label: "Desembolso en efectivo ",
              disabled: false
            }
          } else {
            return {
              ...obj
            }
          }
        })
        .filter(obj => {
          if (obj.value === 1406 || obj.value === 1405 || obj.value === 1401) {
            return true
          }
          return false
        })
        .sort((a, b) => {
          if (a.idx > b.idx) {
            return 1
          }
          if (a.idx < b.idx) {
            return -1
          }
          return 0
        })
    }
  }
}
</script>
