<template>
  <nav class="navbar" :style="StyleObject" role="navigation" aria-label="main navigation">
    <div class="navbar-brand">
      <div class="columns">
        <div class="column is-one-quarter">
          <Slide disableOutsideClick >
            <a @click="search()" id="search" href="#">
              <font-awesome-icon icon="search"></font-awesome-icon>
              <span>Search</span>
            </a>
            <a  @click="pending()" id="pemding" href="#">
              <font-awesome-icon icon="table"></font-awesome-icon>
              <span>Pending</span>
            </a>
          </Slide>
        </div>
        <div class="column">
          <a class="navbar-item" href="" style="color:#fff">
            <b>{{ title }}</b>
          </a>
        </div>
      </div>
    </div>
    <div class="navbar-end" v-if="isAuthenticated">
      <div class="navbar-item">
        <div class="buttons">
          <a class="button is-primary" @click.prevent="logOut" v-if="isAuthenticated">
            <strong>Salir</strong>
            <span class="icon">
              <font-awesome-icon icon="sign-out-alt"></font-awesome-icon>
            </span>
          </a>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { Slide, Push } from 'vue-burger-menu'

export default {
  data() {
    return {
      openMenu: false
    }
  },
  components: {
    Slide,
    Push
  },
  props:{
    title:{
      type: String,
      default: 'Juancho Te Ayuda',
      require: true
    },
    color:{
      type: String,
      default: '#083863',
      require: true
    }
  },
  methods: {
    logOut() {
      this.$store.dispatch("auth/logout")
      this.$store.dispatch("user/cleanData")
      this.$router.push("/login")
    },
    search() {
      this.cleanData()
      this.$router.push("/user") 
    },
    pending() {
      this.$router.push("/table")
    },
    ...mapActions({
      cleanData: "user/cleanData",
    }),
  },
  computed:{
    ...mapGetters({
      "isAuthenticated": "auth/isAuthenticated"
    }),
    StyleObject: function(){
      return {
        backgroundColor: this.color,
      }
    }
  }
}
</script>

<style>
/* .menu {
  padding: 20px 0;
  height: 100%;
}
.menu-label {
  padding: 10px 10px;
} */

 .bm-burger-button {
    width: 30px;
    height: 20px;
    left: 17px;
    top: 25px;
    cursor: pointer;
    }
    .bm-burger-bars {
      background-color: white;
    }
    .line-style {
      position: absolute;
      height: 20%;
      left: 0;
      right: 0;
    }
    .cross-style {
      position: absolute;
      top: 12px;
      right: 2px;
      cursor: pointer;
    }
    .bm-cross {
      background: #bdc3c7;
    }
    .bm-cross-button {
      height: 24px;
      width: 24px;
    }
    .bm-menu {
      height: 100%; /* 100% Full-height */
      width: 0; /* 0 width - change this with JavaScript */
      position: fixed; /* Stay in place */
      z-index: 1000; /* Stay on top */
      top: 0;
      left: 0;
      background-color: rgb(63, 63, 65); /* Black*/
      overflow-x: hidden; /* Disable horizontal scroll */
      padding-top: 60px; /* Place content 60px from the top */
      transition: 0.5s; /*0.5 second transition effect to slide in the sidenav*/
    }

    .bm-overlay {
      background: white
    }
    .bm-item-list {
      color: #b8b7ad;
      margin-left: 10%;
      font-size: 20px;
    }
    .bm-item-list > * {
      display: flex;
      text-decoration: none;
      padding: 0.7em;
    }
    .bm-item-list > * > span {
      margin-left: 10px;
      font-weight: 700;
      color: white;
    }


</style>
