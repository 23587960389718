
import store from "../store/index"

export default ( permission_required, ErrorMessage="Error de permiso")  => {
    let permissions = store.state.auth.permission || []
    if (!permissions.includes(permission_required)) {
        store.commit("form/setError",{
            errorStatus: true,
            errorMessage: ErrorMessage
        });
        return false;
    }
    return true;
}
