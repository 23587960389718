<template>
  <div class="TermsStyled">
    <label>¿Cual es tu plazo?</label>
    <div class="TermButtonContainer">
      <button
        class="TermButton button is-large "
        @click="changeTerm(index)"
        v-for="(item, index) in values.maxAmount"
        :key="index"
        :class="{ 'is-info': index == values.term }"
      >
        <span>
          {{ index }}
          <div v-if="index == values.term">
            <label>MESES</label>
          </div>
        </span>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    values: Object
  },
  methods: {
    changeTerm(term) {
      this.values.term = parseInt(term, 10)
      this.values.amount = this.values.maxAmount[this.values.term]
    }
  }
}
</script>
<style scoped>
.TermsStyled {
  margin: 10px 2px;
  text-align: center;
  color: #083863;
}
.TermsStyled > label {
  font-size: 18px;
  font-weight: bold;
  font-size: 20px;
}
.TermButtonContainer {
  display: flex;
  justify-content: center;
}
.TermButton {
  margin-left: 3px;
  border: 1px solid #e1e1e1;
  margin-right: -1px;
  border-radius: 0;
  flex-grow: 0;
  font-size: 18px;
  font-weight: bold;
  padding: 8px 30px;
  height: 3em;
}
.TermButton :last-child {
  margin-right: 4px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}
.TermButton :first-child {
  margin-left: 4px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}
.TermButton > label {
  font-size: 12px;
}
</style>
