import AWS from "aws-sdk"

const imageToBucket = (image, fileId, side) => {

  let env = process.env
  console.log(env,
  process.env.ACCESS_ID_AWS,
  process.env.ACCESS_SECRET_KEY_AWS,
  process.env.BUCKET_AWS_NAME)

  try {
    let s3 = new AWS.S3({
      accessKeyId: "AKIAXRUZBBY3VFXMW765",
      secretAccessKey: "ACb0EOHDkWEXi/DCz0NA7PD2dnszQfAxm1NR80Fg"
    })

    const base64Data = new Buffer.from(
      image.replace(/^data:image\/\w+;base64,/, ""),
      "base64"
    )

    //let randomId = btoa(`${parseInt(Math.random() * 999999999)}`)
    //console.log(randomId)

    let today = new Date().toISOString().slice(0, 10);

    const params = {
      Bucket: "telephant-ctl",
      Key: `${today}-Client-${side}-${fileId}.png`, // type is not required
      Body: base64Data,
      ContentEncoding: "base64", // required
      ContentType: `image/png` // required. Notice the back ticks
    }

    s3.putObject(params, (err, data) => {
      if (err) throw err
      console.log(data ? "Success" : "")
    })

    return true

  } catch (error) {
    console.log(error)
    return false
  }
}

export default imageToBucket
