<template>
  <div>
    <form @submit.prevent="saveResidentialInformation" autocomplete="off">
      <div class="columns">
        <div class="column">
          <div class="field">
            <label class="label required">Departamento</label>
            <div
              class="select is-fullwidth"
              :class="{ 'is-danger': $v.departmentCode.$error }"
            >
              <select v-model="$v.departmentCode.$model" @change="getCities">
                <option value="">Seleccione una opción</option>
                <option
                  v-for="department in getDepartments"
                  :key="department.value"
                  :value="department.value"
                >
                  {{ department.label }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="column">
          <div class="field">
            <label class="label required">Ciudad</label>
            <div
              class="select is-fullwidth"
              :class="{ 'is-danger': $v.cityCode.$error }"
            >
              <select v-model="$v.cityCode.$model">
                <option value="">Seleccione una opción</option>
                <option
                  v-for="city in cities"
                  :key="city.value"
                  :value="city.value"
                >
                  {{ city.label }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <div class="field">
            <label class="label required">Tipo de residencia</label>
            <div
              class="select is-fullwidth"
              :class="{ 'is-danger': $v.housingType.$error }"
            >
              <select v-model="$v.housingType.$model">
                <option value="">Seleccione una opción</option>
                <option
                  v-for="housingType in getHousingType"
                  :key="housingType.value"
                  :value="housingType.value"
                >
                  {{ housingType.label }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="column">
          <div class="field">
            <label class="label required"
              >Tiempo de permanencia en la residencia</label
            >
            <div
              class="select is-fullwidth"
              :class="{ 'is-danger': $v.stayTime.$error }"
            >
              <select v-model="$v.stayTime.$model">
                <option value="">Seleccione una opción</option>
                <option
                  v-for="stayTime in getStayTime"
                  :key="stayTime.value"
                  :value="stayTime.value"
                >
                  {{ stayTime.label }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="column">
          <div class="field">
            <label class="label required">Barrio</label>
            <div class="control">
              <input
                class="input"
                type="text"
                v-model.trim="$v.neighborhood.$model"
                :class="{ 'is-danger': $v.neighborhood.$error }"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <div class="field">
            <label class="label required">Dirección de residencia</label>
            <div class="control">
              <input
                class="input"
                type="text"
                v-model.trim="$v.address.$model"
                :class="{ 'is-danger': $v.address.$error }"
                @focus="$v.addressSelected.$model = ''"
              />
            </div>
          </div>
        </div>
        <div class="column">
          <div class="field">
            <label class="label">Direcciones recuperadas del cliente</label>
            <div class="control">
              <div v-for="address in getAddresses" :key="address.id">
                <label class="radio">
                  <input
                    type="radio"
                    v-model="$v.addressSelected.$model"
                    :value="address.id"
                    @click="$v.address.$model = ''"
                  />
                  <span> {{ address.address }}</span>
                </label>
              </div>
              <div v-if="getAddresses.length == 0">
                No tiene direcciones registradas.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-4 is-offset-4">
          <div class="buttons">
            <button
              class="button is-primary is-medium is-fullwidth"
              :disabled="$v.$anyError"
            >
              Siguiente
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex"
import { required, requiredIf } from "vuelidate/lib/validators"
import validatePermissions from "../../helpers/validatePermissions"
import { getCitiesByDepartment } from "@/services/user"
export default {
  data() {
    return {
      departmentCode: "",
      cities: [],
      cityCode: "",
      neighborhood: "",
      address: "",
      addressSelected: "",
      housingType: "",
      stayTime: ""
    }
  },
  validations: {
    departmentCode: { required },
    cityCode: { required },
    neighborhood: { required },
    address: {
      required: requiredIf(function() {
        return this.addressSelected == ""
      })
    },
    addressSelected: {
      required: requiredIf(function() {
        return this.address == ""
      })
    },
    housingType: { required },
    stayTime: { required }
  },
  beforeMount(){
    validatePermissions("INFO_RES","No tiene permisos para gestionar a un cliente en la pantalla de Informacion Residencial");
  },
  mounted() {
    this.loadInitial()
  },
  methods: {
    async loadInitial() {
      let personalInformation = this.getPersonalInformation
      this.departmentCode = personalInformation.departmentCode || ""
      this.cityCode = personalInformation.cityCode || ""
      if (this.departmentCode !== "") await this.getCities()
      this.neighborhood = personalInformation.neighborhood || ""
      this.address = personalInformation.address || ""
      this.housingType = personalInformation.housingType || ""
      this.stayTime = personalInformation.stayTime || ""
    },
    async getCities() {
      try {
        let response = await getCitiesByDepartment(this.departmentCode)
        this.cities = response
      } catch (error) {
        this.cities = []
        this.$notyf.error(error)
      }
    },
    saveResidentialInformation() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        let personalInformation = this.$store.getters[
          "user/getPersonalInformation"
        ]
        let address_checkbox = this.getAddresses.find(
          address => this.addressSelected == address.id
        )
        personalInformation.departmentCode = this.departmentCode
        personalInformation.cityCode = this.cityCode
        personalInformation.neighborhood = this.neighborhood
        personalInformation.address =
          this.address !== "" ? this.address : address_checkbox.address
        personalInformation.housingType = this.housingType
        personalInformation.stayTime = this.stayTime
        this.setPersonalInformation(personalInformation)
        this.completeFormStep("residentialInformation")
      }
    },
    ...mapMutations({
      setPersonalInformation: "user/setPersonalInformation",
      completeFormStep: "user/completeFormStep"
    })
  },
  computed: {
    ...mapGetters({
      getPersonalInformation: "user/getPersonalInformation",
      getDepartments: "user/getDepartments",
      getHousingType: "user/getHousingType",
      getStayTime: "user/getStayTime",
      getAddresses: "user/getUbicaAddresses"
    })
  }
}
</script>

<style></style>
