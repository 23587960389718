import periodicPaymentAmount from "./periodicPaymentAmount"

export default ({
  amount,
  term,
  interest,
  electronicSignature,
  guaranteeVariable,
}) => {
  const termDays = term * 30
  const interestMonthly = Math.pow(1 + interest, 1 / 12) - 1
  const valueAvalVariable = amount * guaranteeVariable * termDays
  const valueElectronicSignature = electronicSignature / termDays

  const quote =
    periodicPaymentAmount(interestMonthly, term, amount) +
    valueElectronicSignature * 30 +
    valueAvalVariable / term

  return quote
}
