<template>
  <div class="container">
    <div class="container-label">
      <label class="label">¿Cuanto vas a prestar?</label>
      <div class="amount">
        {{ amount }}
      </div>
    </div>
    <div class="container-slider">
      <input
        class="slider is-fullwidth has-output"
        id="slider"
        :step="step"
        :min="minAmount"
        :max="max"
        type="range"
        :value="value"
        @change="changeAmount($event)"
      />
    </div>
    <div class="columns is-mobile">
      <div class="column has-text-left">
        {{ format(minAmount) }}
      </div>
      <div class="column has-text-right">
        {{ format(max) }}
      </div>
    </div>
  </div>
</template>
<script>
import moneyFormat from "@/helpers/moneyFormat"
//import figure from "@/assets/btn_monto.png"

export default {
  data() {
    return {
      value: 0
    }
  },
  props: {
    values: Object,
    step: Number
  },
  mounted() {
    this.value = this.values.amount
  },
  updated() {
    this.value = this.values.amount
  },
  computed: {
    minAmount() {
      return this.values.minAmount
    },
    amount() {
      return moneyFormat(this.values.amount)
    },
    max() {
      return this.values.maxAmount[this.values.term]
    }
  },
  methods: {
    changeAmount(e) {
      this.values.amount = e.target.value
    },
    format(value) {
      return moneyFormat(value)
    }
  }
}
</script>
<style scoped>
input[type="range"]::-webkit-slider-thumb {
  position: relative;
  /* background-image: url("@/assets/btn_monto.png"); */
  background-repeat: no-repeat;
  background-position: 50% 40%;
  background-size: 55px 55px;
  width: 2rem;
  height: 2rem;
  margin-top: -0.55rem;
  background-color: red;
  border: 3px solid white;
  border-radius: 2rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  appearance: none;
}

.slider:focus {
  outline: none;
}

.slider:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.slider:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.slider:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.slider::-moz-focus-outer {
  border: 0;
}

.slider::-webkit-slider-thumb:active {
  background-color: red;
}

.slider::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}

.slider::-moz-range-thumb {
  /* background-image: url("@/assets/btn_monto.png"); */
  background-repeat: no-repeat;
  background-position: 50% 40%;
  background-size: 55px 55px;
  width: 2rem;
  height: 2rem;
  margin-top: -0.55rem;
  background-color: red;
  border: 3px solid white;
  border-radius: 2rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  appearance: none;
}

.slider::-moz-range-thumb:active {
  background-color: red;
}

.slider::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 2rem;
}

.slider::-ms-thumb {
  /* background-image: url("@/assets/btn_monto.png"); */
  background-repeat: no-repeat;
  background-position: 50% 40%;
  background-size: 55px 55px;
  width: 2rem;
  height: 2rem;
  margin-top: -0.55rem;
  background-color: red;
  border: 3px solid white;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  appearance: none;
}

.slider::-ms-thumb:active {
  background-color: red;
}

.slider::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}

.slider::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}

.slider::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem;
}

.slider:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}

.slider:disabled::-webkit-slider-runnable-track {
  cursor: default;
}

.slider:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}

.slider:disabled::-moz-range-track {
  cursor: default;
}

.slider:disabled::-ms-thumb {
  background-color: #adb5bd;
}

.container {
  margin: 10px 2px;
  text-align: center;
}
.label {
  font-size: 18px;
  margin-top: 15px;
  margin-bottom: 1px;
}
.amount {
  text-align: center;
  font-weight: bold;
  font-size: 20px;
}
.slider {
  width: 100%;
}
</style>
