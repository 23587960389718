<template>
  <div>
    <form @submit.prevent="submit" autocomplete="off">
      <div class="columns">
        <div class="column is-8 is-offset-2">
          <div class="field">
            <label class="label required">
              Resultado
            </label>
            <div
              class="select is-fullwidth"
              :class="{
                'is-danger': $v.jtaResult.$error
              }"
            >
              <select v-model="$v.jtaResult.$model">
                <option value="">Seleccione una opción</option>
                <option
                  v-for="option in ListSelector"
                  :key="option.value"
                  :value="option.value"
                >
                  {{ option.label }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-8 is-offset-2">
          <div class="field">
            <label class="label required">Comentarios</label>
            <div class="control">
              <textarea 
                class="textarea" 
                :class="{
                  'is-danger': $v.jtaNotes.$error
                }" 
                v-model="$v.jtaNotes.$model"></textarea>
            </div>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-8 is-offset-2">
          <div class="buttons">
            <button
              class="button is-primary is-medium is-fullwidth"
              :disabled="isLoading || $v.$anyError"
            >
              Continuar
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapMutations, mapGetters, mapActions } from "vuex"
import validatePermissions from "../../helpers/validatePermissions"
import { required } from "vuelidate/lib/validators"
export default {
  data() {
    return {
      isLoading: false,
      jtaResult: "",
      jtaNotes: "",
    }
  },
  beforeMount(){
    validatePermissions("RES_VAL","No tiene permisos para gestionar a un cliente en la pantalla de Predesembolso Automático");
  },
  validations: {
    jtaResult: { required },
    jtaNotes: { required },
  },
  computed: {
    ...mapGetters({
      ListSelector: "user/getYesOrNo",
    })
  },
  methods: {
    ...mapMutations({
      setError: "form/setError"
    }),
    ...mapActions({
      postPredisbursement: "user/postPredisbursement"
    }),
    async submit() {
      this.$v.$touch()
      if (!this.$v.$invalid) {

        try {
          
          this.isLoading = true;
          await this.postPredisbursement({
            jtaResult: this.jtaResult,
            jtaNotes: this.jtaNotes
          });
          this.isLoading = false;
          this.setError({
            errorStatus: true,
            errorMessage: "El cliente ha firmado exitosamente los contratos. Ahora Juancho Te Presta necesita un tiempo para desembolsarle y se le avisará cuando todo esté listo."
          })

        } catch (error) {
          this.isLoading = false;
          if(error.code==="R004"){
            this.setError({
              errorStatus: true,
              errorMessage: "El cliente ha firmado exitosamente los contratos. Ahora Juancho Te Presta necesita un tiempo para desembolsarle y se le avisará cuando todo esté listo."
            })
          }else{
            this.$notyf.error(error)
          }
        }
      }
    }
  }
}
</script>

<style></style>
