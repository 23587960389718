<template>
  <div>
    <div class="row">
      <user-input :value="user1" user="user1" :label="currentUserLabel" :disabled="userRegistered" @input="change" />
      <user-input :value="user2" user="user2" :label="toCallUserLabel" :disabled="calling || !userRegistered" @input="change"/>
    </div>
      <div class="p-2">
        <button class="btn btn-outline-primary mx-1 btn-sm" v-if="(user1 && !userRegistered)" :pressed="userRegistered" @click="$emit('register', user1)">Registrar</button>
        <button class="btn btn-outline-success mx-1 btn-sm" v-if="!isHost && userRegistered" :disabled="!user2 || calling" @click="$emit('call', user2)">
          <!-- <b-spinner small type="grow" v-if="calling"></b-spinner> -->
          <font-awesome-icon icon="phone-alt"></font-awesome-icon>
          {{ calling ? "Llamando..." : `Llamar` }}
        </button>
        <button class="btn btn-outline-success mx-1 btn-sm" v-if="isHost && userRegistered" :disabled="!user2" @click="generateUserlink">
          Obtener link para cliente
        </button>
      </div>
    </div>
</template>

<script>
/* eslint-disable */
import User_input from "./User_input.vue"
import { getQueryParamValue } from "./Telephant.vue"


export default {
  components: {
    "user-input": User_input,
  },
  props: {
    userRegistered: Boolean,
    calling: Boolean,
    currentUsername: {
      type: String,
      required: true,
    },
    toCallUsername: {
      type: String,
      required: true,
    },
    isHost: Boolean,
    test:{
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      user1: this.currentUsername ?? "",
      user2: this.toCallUsername ?? "",
      callPressed: false,
    };
  },
  computed: {
    currentUserLabel() {
      return this.isHost ? 'Nombre de usuario: ' : 'Tu número de teléfono: '
    },
    toCallUserLabel() {
      return this.isHost ? 'Teléfono del cliente: ' : 'Agente: ';
    },
  },
  methods: {
    generateUserlink() {
      const env = process.env
	    const VUE_APP_JANUS_CLIENT = env.NODE_ENV == "development"  ? `${window.origin}` : `${env.VUE_APP_JANUS_CLIENT}`
      let text = "";
      if(this.test){
        let color = getQueryParamValue("color").replace("#","%23")
        let title = getQueryParamValue("title")
        text = `${VUE_APP_JANUS_CLIENT}/telephantTest?phone=${this.user2}&agent=${this.user1}&color=${color}&title=${title}`;
      }else{
        text = `${VUE_APP_JANUS_CLIENT}/telephant?phone=${this.user2}&agent=${this.user1}`;
      }
        window.prompt("Copiar al portapapeles: Ctrl+C, Enter", text);   
    },
    change(data){
      if(data.user === "user1") this.user1 = data.value
      if(data.user === "user2") this.user2 = data.value
      this.$emit('changeUser', data);
    }
  }
}
</script>