<template>
  <div class="modal" :class="{ 'is-active': open }">
    <div class="modal-background"></div>
    <div class="modal-card">
      <section class="modal-card-body p-0">
        <article :class="['message', variant]">
          <div class="message-header">
            <p>{{ title }}</p>
          </div>
          <div class="message-body mt-4">
            <strong>{{ message }}</strong>
          </div>
        </article>
      </section>
      <footer class="modal-card-foot">
        <button class="button is-success is-outlined" v-if="accept" @click="accept">{{ acceptLabel }}</button>
        <button class="button is-danger is-outlined" v-if="cancel" @click="cancel"> {{ cancelLabel }} </button>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    open: {
      type: Boolean,
      default: false
    },
    variant:{
      type: String,
    },
    title: {
      type: String,
      required: true
    },
    message: {
      type: String,
      required: true
    },
    acceptLabel:{
      type: String,
      default: 'Aceptar'
    },
    accept: {
      type: Function,
      default: undefined
    },
    cancelLabel:{
      type: String,
      default: 'Cancelar'
    },
    cancel: {
      type: Function,
      default: undefined
    }
  },
}
</script>
